import { RootState } from "Reducers/index";

export const navigationHistorySelector = (state: RootState) => {
  return state.navigation.locationStack;
};

export const comingFromLoginSelector = (state: RootState) => {
  const navStack = navigationHistorySelector(state);
  // Exclude authentication routes from the navigation stack
  // before determining how many routes they have navigated.
  const postAuthNavStack = navStack.filter(
    n => !["login", "sign_up", "logout"].some(p => n.includes(p))
  );
  // If they haven't hit any other routes post-auth, they're coming from login
  return postAuthNavStack.length <= 1;
};
