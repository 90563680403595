import React, { useCallback, useContext, createContext, ReactNode, useEffect, useRef } from "react";

import { BTC } from "Contexts/BuyBitcoin";

import { makeStore } from "..";
import { TradingAPI } from "../../shared/api/tradingApi";
import {
  SET_TRADING_JWT,
  WEB_SOCKET_CLOSED,
  WEB_SOCKET_ERROR,
  WEB_SOCKET_NULL,
} from "../BuyBitcoin/buyBitcoinConstants";
import {
  setSellBitcoinStreamingQuoteStatus,
  setSellBitcoinWebSocketStatus,
} from "./SellBitcoinActions";
import {
  SET_SELL_AMOUNT,
  SET_SELL_BITCOIN_WEB_SOCKET_STATUS,
  SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS,
  SET_SELL_BITCOIN_SOURCE,
  SET_SELECTED_BANK_ACCOUNT,
  SET_SELECTED_KEYS,
  SET_IS_SHOW_CANCEL_MODAL,
  SET_IS_SELL_BITCOIN_AVAILABLE,
  SET_SELL_INFO,
  SET_SELL_IN_PROGRESS_DATA,
  SET_CURRENT_SALE_UUID,
  DELETE_SELL_BITCOIN_IN_PROGRESS,
  SET_SELL_BITCOIN_SUCCESS,
  SET_STREAMING_QUOTE_SELL_RESPONSE,
  SET_SAVE_IN_PROGRESS_AND_CLOSE,
  SET_CLOSE_CONFIRMED_SALE,
  SET_RECEIVING_ACCOUNT_TYPE,
  SET_ORG_UUID,
} from "./SellBitcoinConstants";
import {
  SellBitcoinInitialState,
  SellBitcoinActions,
  SellStatus,
  SellStreamingQuoteMessageTypes,
  SellStreamingQuoteStatus,
  streamingQuoteConfirmation,
} from "./types";

const reducer = (
  state: SellBitcoinInitialState,
  action: SellBitcoinActions
): SellBitcoinInitialState => {
  switch (action.type) {
    case SET_ORG_UUID: {
      const { orgUuid, isIraOrg } = action.payload;
      return { ...state, orgUuid, isIraOrg };
    }
    case SET_SELL_AMOUNT: {
      const { amount } = action.payload;
      return { ...state, sellAmount: amount };
    }
    case SET_SELL_BITCOIN_WEB_SOCKET_STATUS: {
      const { status } = action.payload;

      const validClosedStreamingQuoteStatus = [
        SellStreamingQuoteStatus.STREAMING_QUOTE_NOT_INITIATED,
        SellStreamingQuoteStatus.CLIENT_CLOSED_STREAMING_QUOTE,
      ];

      // If web socket is closed but the streamingQuoteStatus is in a state that
      // is actively expecting a response then the web socket is in an unexpected state.
      const isUnexpectedWebSocketFailure =
        status === WEB_SOCKET_CLOSED &&
        !validClosedStreamingQuoteStatus.includes(state.streamingQuoteStatus);

      const sellBitcoinWebSocketStatus = isUnexpectedWebSocketFailure ? WEB_SOCKET_ERROR : status;

      // if the web socket is in an unexpected error state
      // reset the streamingQuoteStatus to the default starting state
      // STREAMING_QUOTE_NOT_INITIATED
      const streamingQuoteStatus = isUnexpectedWebSocketFailure
        ? SellStreamingQuoteStatus.STREAMING_QUOTE_NOT_INITIATED
        : state.streamingQuoteStatus;

      return { ...state, sellBitcoinWebSocketStatus, streamingQuoteStatus };
    }
    case SET_TRADING_JWT: {
      const { tradingJwt } = action.payload;
      return { ...state, tradingJwt: tradingJwt };
    }
    case SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS: {
      const { streamingQuoteStatus } = action.payload;
      return {
        ...state,
        streamingQuoteStatus: streamingQuoteStatus,
      };
    }

    case SET_SELL_BITCOIN_SOURCE: {
      const { source } = action.payload;
      return { ...state, selectedSource: source };
    }

    case SET_SELECTED_BANK_ACCOUNT: {
      const { selectedBankAccount } = action.payload;
      return {
        ...state,
        selectedBankAccount,
        bankName: selectedBankAccount.name,
        bankLastFourDigits: selectedBankAccount.account_number.slice(
          selectedBankAccount.account_number.length - 4,
          selectedBankAccount.account_number.length
        ),
      };
    }

    case SET_RECEIVING_ACCOUNT_TYPE: {
      const { receivingAccountType } = action.payload;
      return {
        ...state,
        receivingAccountType,
      };
    }

    case SET_SELECTED_KEYS: {
      const { selectedKeys } = action.payload;
      return { ...state, selectedKeys };
    }

    case SET_IS_SHOW_CANCEL_MODAL: {
      const { isShowCancelModal } = action.payload;
      return { ...state, isShowCancelModal };
    }

    case SET_IS_SELL_BITCOIN_AVAILABLE: {
      const { isSellAvailable, isSaleInProgress } = action.payload;
      return {
        ...state,
        isSellingFeatureAvailable: isSellAvailable,
        isSaleInProgress: isSaleInProgress,
      };
    }

    case SET_SELL_INFO: {
      const {
        bitcoinPrice,
        sources,
        sellStatus,
        minimumSaleAmount,
        maximumSaleAmount,
        availableSaleAmountBTC,
        offlineStatus,
        feeRates,
        bankAccounts,
        currentSaleUuid,
        sellLimitBTC,
        jwt,
        cashBalanceUsd,
      } = action.payload;
      return {
        ...state,
        bitcoinPrice,
        sources,
        sellStatus,
        minimumSaleAmount,
        maximumSaleAmount,
        availableSaleAmountBTC,
        offlineStatus,
        feeRates,
        bankAccounts,
        currentSaleUuid,
        sellLimitBTC,
        tradingJwt: jwt,
        cashBalanceUsd,
      };
    }

    case SET_SELL_IN_PROGRESS_DATA: {
      const {
        sellAmount,
        saleAmountBTC,
        saleAmountUSD,
        amountUSDTobeSentToClient,
        feeAmountUSD,
        transactionFeeSatsVByte,
        transactionFeeAmountUSD,
        transactionFeeAmountBTC,
        customerBTCChangeAddress,
        unchainedBTCReceiveAddress,
        bankName,
        bankLastFourDigits,
        selectedSource,
        selectedKeys,
        transactionUuid,
        hasReviewedTx,
        txVideoVerification,
        hasConfirmedSignatures,
        hasBroadcastTx,
        allKeys,
        receivingAccountType,
      } = action.payload;

      // if the sellAmount is set then the user has completed the sale details step
      // and therefore has an active sale in progress
      const isSaleInProgress = !!sellAmount;
      return {
        ...state,
        isSaleInProgress,
        sellAmount,
        saleAmountBTC,
        saleAmountUSD,
        amountUSDToBeSentToClient: amountUSDTobeSentToClient,
        feeAmountUSD,
        transactionFeeSatsVByte,
        transactionFeeAmountUSD,
        transactionFeeAmountBTC,
        customerBTCChangeAddress,
        unchainedBTCReceiveAddress,
        bankName,
        bankLastFourDigits,
        selectedSource,
        allKeys,
        selectedKeys,
        transactionUuid,
        hasReviewedTx,
        txVideoVerification: {
          isVerificationNeeded: !!txVideoVerification.isVerificationNeeded,
          isVideoVerified: !!txVideoVerification.isVideoVerified,
        },
        hasConfirmedSignatures,
        hasBroadcastTx,
        receivingAccountType,
      };
    }

    case SET_SAVE_IN_PROGRESS_AND_CLOSE: {
      return {
        ...initialState,
        isSellingFeatureAvailable: state.isSellingFeatureAvailable,
        isSaleInProgress: state.isSaleInProgress,
        currentSaleUuid: state.currentSaleUuid,
      };
    }

    case SET_CLOSE_CONFIRMED_SALE: {
      return {
        ...initialState,
        isSellingFeatureAvailable: state.isSellingFeatureAvailable,
        isSaleInProgress: state.isSaleInProgress,
        currentSaleUuid: state.currentSaleUuid,
      };
    }
    case SET_CURRENT_SALE_UUID: {
      const { currentSaleUuid } = action.payload;
      return {
        ...state,
        currentSaleUuid: currentSaleUuid,
      };
    }

    case DELETE_SELL_BITCOIN_IN_PROGRESS: {
      return {
        ...initialState,
        isSellingFeatureAvailable: state.isSellingFeatureAvailable,
      };
    }

    case SET_STREAMING_QUOTE_SELL_RESPONSE: {
      const {
        BTCPrice,
        sellAmountBTC,
        sellAmountUSD,
        feeAmountUSD,
        totalAmountUSD,
        streamingQuoteId,
        streamingQuoteVersion,
      } = action.payload;

      return {
        ...state,
        bitcoinPrice: BTCPrice,
        sellAmount: sellAmountBTC,
        saleAmountBTC: sellAmountBTC,
        saleAmountUSD: sellAmountUSD,
        feeAmountUSD: feeAmountUSD,
        amountUSDToBeSentToClient: totalAmountUSD,
        streamingQuoteId: streamingQuoteId,
        streamingQuoteVersion: streamingQuoteVersion,
        streamingQuoteStatus: SellStreamingQuoteStatus.LOADING_INCOMING_STREAMING_QUOTE,
      };
    }

    case SET_SELL_BITCOIN_SUCCESS: {
      const { BTCPrice, sellAmountBTC, sellAmountUSD, feeAmountUSD, totalAmountUSD, tradeStatus } =
        action.payload;

      const streamingQuoteStatus =
        tradeStatus === streamingQuoteConfirmation.ACCEPTED
          ? SellStreamingQuoteStatus.SERVER_CONFIRMED_THE_ACCEPTED_STREAMING_QUOTE
          : SellStreamingQuoteStatus.SERVER_DENIED_THE_ACCEPTED_STREAMING_QUOTE;

      return {
        ...state,
        bitcoinPrice: BTCPrice,
        sellAmount: sellAmountBTC,
        saleAmountBTC: sellAmountBTC,
        saleAmountUSD: sellAmountUSD,
        feeAmountUSD: feeAmountUSD,
        amountUSDToBeSentToClient: totalAmountUSD,
        streamingQuoteStatus: streamingQuoteStatus,
      };
    }

    default: {
      return state;
    }
  }
};

export const initialState: SellBitcoinInitialState = {
  orgUuid: "",
  isIraOrg: false,
  isSellingFeatureAvailable: false,
  isSaleInProgress: false,
  sellAmount: null,
  sellStatus: null,
  sellBitcoinWebSocketStatus: WEB_SOCKET_NULL,
  tradingJwt: null,
  streamingQuoteStatus: SellStreamingQuoteStatus.STREAMING_QUOTE_NOT_INITIATED,
  streamingQuoteId: null,
  streamingQuoteVersion: null,
  bankName: "",
  bankLastFourDigits: "",
  bitcoinPrice: "",
  saleAmountBTC: "",
  saleAmountUSD: "",
  amountUSDToBeSentToClient: "",
  feeAmountUSD: "",
  feeRates: undefined,
  sellLimitBTC: "",
  minimumSaleAmount: { BTC: "", USD: "" },
  maximumSaleAmount: { BTC: "", USD: "" },
  availableSaleAmountBTC: "",
  sources: { vaults: [] },
  selectedSource: null,
  transactionUuid: "",
  transactionFeeAmountUSD: "",
  transactionFeeAmountBTC: "",
  transactionFeeSatsVByte: "",
  bankAccounts: [],
  unchainedBTCReceiveAddress: "",
  customerBTCChangeAddress: "",
  selectedBankAccount: null,
  selectedKeys: [],
  allKeys: [],
  isShowCancelModal: false,
  currentSaleUuid: null,
  offlineStatus: { description: "", title: "" },
  hasReviewedTx: false,
  txVideoVerification: { isVerificationNeeded: true, isVideoVerified: false },
  hasConfirmedSignatures: false,
  hasBroadcastTx: false,
  cashBalanceUsd: "",
  receivingAccountType: undefined,
};

const [SellBitcoinProvider, useSellBitcoinStore, useSellBitcoinDispatch] = makeStore(
  reducer,
  initialState
);

const SocketContext = createContext(null);

interface SellBitcoinWebSocketProviderProps {
  children: ReactNode;
}

const SellBitcoinWebSocketProvider = ({ children }: SellBitcoinWebSocketProviderProps) => {
  const dispatch = useSellBitcoinDispatch();
  const { tradingJwt } = useSellBitcoinStore();
  const ws = useRef(null);

  useEffect(() => {
    try {
      if (tradingJwt) {
        ws.current = TradingAPI.OpenSellBitcoinWebSocket(tradingJwt);
        // set onopen, onclose, onerror, and onmessage methods
        TradingAPI.SetSellBitcoinWebSocketLifeCycleMethods(ws.current, dispatch);
      }
    } catch (err) {
      dispatch(setSellBitcoinWebSocketStatus(WEB_SOCKET_ERROR));
    }
  }, [dispatch, tradingJwt]);

  return <SocketContext.Provider value={ws.current}>{children}</SocketContext.Provider>;
};

const useSellBitcoinSocket = () => {
  const socket = useContext(SocketContext);
  const { streamingQuoteId, streamingQuoteVersion } = useSellBitcoinStore();
  const dispatch = useSellBitcoinDispatch();

  const send = useCallback(
    payload => {
      socket?.send(JSON.stringify(payload));
    },
    [socket]
  );

  const getStreamingQuote = React.useCallback(
    (amount: string) => {
      const purchaseAmount = amount;
      const payload = { amount: purchaseAmount, currency: BTC };

      new Promise(() => {
        send(payload);
      })
        .then(() => {
          dispatch(
            setSellBitcoinStreamingQuoteStatus(
              SellStreamingQuoteStatus.AWAITING_INITIAL_STREAMING_QUOTE
            )
          );
        })
        .catch(err => {
          // don't handle the error here,
          // instead let the exisitng try again handling / timeout error handling
          // in the FinalizeSaleStep handle this error.
        });
    },
    [send, dispatch]
  );

  const acceptSellRequest = React.useCallback(() => {
    send({
      confirmed: true,
      version: streamingQuoteVersion,
      id: streamingQuoteId,
    });
    dispatch(
      setSellBitcoinStreamingQuoteStatus(SellStreamingQuoteStatus.CLIENT_ACCEPTED_STREAMING_QUOTE)
    );
  }, [streamingQuoteVersion, streamingQuoteId, send, dispatch]);

  const stopStreamingQuote = React.useCallback(() => {
    send({
      messageType: SellStreamingQuoteMessageTypes.STREAMING_QUOTE_CLOSE_REQUEST,
      version: streamingQuoteVersion,
      id: streamingQuoteId,
    });
    dispatch(
      setSellBitcoinStreamingQuoteStatus(SellStreamingQuoteStatus.CLIENT_CLOSED_STREAMING_QUOTE)
    );
    if (socket?.close) {
      socket.close();
    }
  }, [streamingQuoteVersion, streamingQuoteId, send, dispatch, socket]);
  return { socket, getStreamingQuote, acceptSellRequest, stopStreamingQuote };
};

export {
  SellBitcoinProvider,
  useSellBitcoinStore,
  useSellBitcoinDispatch,
  useSellBitcoinSocket,
  SellBitcoinWebSocketProvider,
};
