import { ReactNode } from "react";

import { CircularProgress, InputAdornment, MenuItem } from "@mui/material";

import { AvatarRowLeft } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/AvatarRows";
import { orgDisplayInfo } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/accountHelpers";
import { DeprecatedTextField } from "Components/Shared/Elements/Fields/Deprecated/DeprecatedTextField";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";
import { GetUserWorkspaceData200 } from "Specs/v2/getUserWorkspaceData/200";

export default withWorkspaceData(
  ({
    orgs,
    onOrgSelect,
    orgUuid,
    error,
    helpText,
    user,
    className,
    isLoading = false,
  }: GetUserWorkspaceData200 & {
    onOrgSelect: (orgUuid: string) => void;
    orgUuid: string;
    error?: boolean;
    helpText?: ReactNode;
    className?: string;
    isLoading?: boolean;
  }) => {
    const orgMenuItems = orgs.map(org => {
      const { Avatar: OrgAvatar, color } = orgDisplayInfo({
        ...org,
        account_type: org.accountType,
      });
      return (
        <MenuItem key={org.id} value={org.id}>
          <AvatarRowLeft
            avatar={<OrgAvatar title={org.title} className={`bg-[${color}]`} />}
            title={org.title}
            subtitle={org.subtitle}
          />
        </MenuItem>
      );
    });
    return (
      <div className={className}>
        <div className="flex items-center">
          <p className="text-sm font-med text-gray-600">Unchained account</p>
        </div>
        <DeprecatedTextField
          name="orgUuid"
          select
          disabled={!user.canViewUnifiedIA}
          onChange={e => onOrgSelect(e.target.value)}
          error={!!orgUuid && error}
          helperText={!!orgUuid && !!helpText && helpText}
          value={orgUuid}
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: string) => {
              const org = orgs.find(o => o.id === value);

              if (org) {
                const { Avatar: OrgAvatar, color } = orgDisplayInfo({
                  ...org,
                  account_type: org.accountType,
                });
                return (
                  <AvatarRowLeft
                    avatar={<OrgAvatar title={org.title} className={`bg-[${color}]`} />}
                    title={org.title}
                    subtitle={org.subtitle}
                    className="-m-2 !py-0 !pl-2"
                  />
                );
              }
              return <span className="font-bold text-gray-500">- Select -</span>;
            },
            MenuProps: {
              "data-testid": "orgSelectMenu",
            },
            SelectDisplayProps: {
              "data-testid": "orgSelect",
            },
            endAdornment: isLoading ? (
              <InputAdornment position="end">
                <CircularProgress className="bg-white" size={20} />
              </InputAdornment>
            ) : undefined,
          }}
          fullWidth
        >
          {orgMenuItems}
        </DeprecatedTextField>
      </div>
    );
  }
);
