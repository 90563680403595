import { useState } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import { Collapse, Divider } from "@mui/material";
import { AddressString, Button, Tooltip } from "@unchained/component-library";

import { orgDisplayInfo } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/accountHelpers";
import { Fee } from "Contexts/BuyBitcoin/types";
import { ReceivingAccountType } from "Contexts/SellBitcoin/types";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { formatCurrency } from "Utils/strings";

import { PreviewContentItem } from "../../components/PreviewContentItem";
import { TradingFeesTooltip } from "../../components/TradingFeesTooltip";
import {
  FeeEstimateSummary,
  FeeRateSummary,
  ReceivingBankAccountSummary,
  ReceivingCashAccountSummary,
} from "../steps/ReviewTransactionStep";

const itemContainerStyles = "flex flex-row justify-between mt-3";
const itemContentStyles = "gray-600, m-0 text-md font-med";
const itemTitleStyles = "gray-600, m-0 text-md font-reg";

type SaleSummaryCardProps = {
  vaultName: string;
  vaultId: string;
  saleAmountBTC: string;
  streamingQuoteUniqueId?: string;
  isFirstStreamingQuote?: boolean;
  saleAmountUSD: string;
  feeRates: Fee[];
  bitcoinPrice: string;
  feeAmountUSD: string;
  amountUSDToBeSentToClient: string;
  isSaleComplete: boolean;
  btcRecieveAddress?: string;
  btcRecieveAddressUnchained: string;
  transactionFeeAmountBTC: string;
  transactionFeeAmountUSD: string;
  bankAccountName: string;
  bankAccountLastFourDigits: string;
  transactionFeeSatsVByte: string;
  receivingAccountType: ReceivingAccountType;
  cashBalanceUsd?: string;
  isIraOrg: boolean;
  orgUuid: string;
};
export const SaleSummaryCard = ({
  vaultName,
  vaultId,
  saleAmountBTC,
  streamingQuoteUniqueId,
  isFirstStreamingQuote,
  feeRates,
  saleAmountUSD,
  bitcoinPrice,
  feeAmountUSD,
  amountUSDToBeSentToClient,
  isSaleComplete,
  transactionFeeAmountBTC,
  transactionFeeAmountUSD,
  btcRecieveAddressUnchained,
  btcRecieveAddress,
  bankAccountName,
  bankAccountLastFourDigits,
  transactionFeeSatsVByte,
  receivingAccountType,
  cashBalanceUsd,
  isIraOrg,
  orgUuid,
}: SaleSummaryCardProps) => {
  const feeCostFormatted = feeAmountUSD ? `$${formatCurrency(feeAmountUSD)}` : "---";
  const backgroundColor = isSaleComplete ? "bg-primary-50" : "bg-gray-50 border-gray-200";
  const btcAmountColor = isSaleComplete ? "text-primary-700" : "text-gray-800";
  const dividerColor = isSaleComplete ? "border-primary-300" : "bg-gray-300";

  const [isShowAdditionalDetails, setIsShowAdditionalDetails] = useState(false);

  const handleShowDetails = () => {
    setIsShowAdditionalDetails(true);
  };
  const handleHideDetails = () => {
    setIsShowAdditionalDetails(false);
  };

  const workspaceData = useWorkspaceData();
  const org = workspaceData.data?.orgs.find(o => o.id === orgUuid);
  const { Avatar: OrgAvatar, color } = orgDisplayInfo({
    ...org,
    account_type: org.accountType,
  });

  return (
    <div>
      <div
        data-testid="sellBitcoinFinalizeStepContentContainer"
        className={`flex flex-col justify-between border border-gray-200 p-4 ${backgroundColor}`}
      >
        <div className="mb-4 flex flex-row justify-between gap-4">
          <div>
            <div className="text-sm uppercase tracking-widest text-gray-600">Sale amount</div>
            <div className="text-d-sm font-semi" data-testid="sale-amount">
              {saleAmountBTC} BTC
            </div>
          </div>
          <div>
            <div className="text-sm uppercase tracking-widest text-gray-600">
              Selling Account & vault
            </div>
            <div className="mt-2 flex flex-row gap-4">
              <div className="flex items-center gap-2">
                <OrgAvatar title={org.title} className={`bg-[${color}]`} />
                <div className="flex flex-col">
                  <p className="font-med">{org.title}</p>
                  <p className="text-xs text-gray-400">{org.subtitle}</p>
                </div>
              </div>
              <div className="mt-[1px] flex flex-col">
                <p className="font-med">{vaultName}</p>
                <p className="text-xs text-gray-400">{vaultId}</p>
              </div>
            </div>
          </div>
        </div>
        <Divider className={dividerColor} />
        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-bitcoinPricekey`}
          animateOnChange={!isFirstStreamingQuote}
          title="Bitcoin price"
          content={`$${formatCurrency(bitcoinPrice)}`}
          containerStyles={itemContainerStyles}
          contentStyles={itemContentStyles}
          titleStyles={itemTitleStyles}
        />

        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-saleAmountUSDKey`}
          animateOnChange={!isFirstStreamingQuote}
          title={`Amount`}
          content={`$${formatCurrency(saleAmountUSD)}`}
          containerStyles={itemContainerStyles}
          contentStyles={itemContentStyles}
          titleStyles={itemTitleStyles}
        />
        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-FeeKey`}
          animateOnChange={!isFirstStreamingQuote}
          title={
            <>
              {[
                "Unchained fee",
                <TradingFeesTooltip
                  infoIconClass={"ml-1"}
                  key="tradingFeesToolTip"
                  fees={feeRates}
                />,
              ]}
            </>
          }
          content={feeCostFormatted}
          containerStyles="mb-4 flex flex-row justify-between mt-3"
          contentStyles={itemContentStyles}
          titleStyles={itemTitleStyles}
        />
        <Divider className={dividerColor} />
        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-wireAmountOwed`}
          animateOnChange={!isFirstStreamingQuote}
          title={
            <>
              {isIraOrg
                ? "Amount to be sent to IRA cash balance"
                : "Amount to be sent to your account"}
              {isSaleComplete ? null : (
                <Tooltip
                  arrow
                  placement="top"
                  compact
                  content={
                    "Due to bitcoin price fluctuations, exact amounts won’t be set until this transaction is finalized and broadcasted."
                  }
                  data-testid="tradingEstimateToolTip"
                >
                  <HelpOutline className={"relative top-[-1px] ml-1 text-lg text-gray-400"} />
                </Tooltip>
              )}
            </>
          }
          content={`$${formatCurrency(amountUSDToBeSentToClient)}`}
          contentStyles={"gray-800, m-0 text-md font-semi"}
          titleStyles={"gray-800, m-0 text-md font-semi"}
          containerStyles="mt-4 flex flex-row justify-between"
        />
      </div>

      {/* additional details section */}

      <div className={`border border-gray-200 ${backgroundColor} mt-4 p-4`}>
        <Collapse in={isShowAdditionalDetails}>
          <>
            <PreviewContentItem
              animateOnChange={false}
              title={"Destination (Unchained)"}
              content={<AddressString bolded address={btcRecieveAddressUnchained} />}
              containerStyles={"flex flex-row justify-between mt-0"}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            {btcRecieveAddress ? (
              <PreviewContentItem
                animateOnChange={false}
                title={"Change address"}
                content={<AddressString bolded address={btcRecieveAddress} />}
                containerStyles={itemContainerStyles}
                contentStyles={itemContentStyles}
                titleStyles={itemTitleStyles}
              />
            ) : null}
            <Divider className={`mb-1 mt-4 ${dividerColor}`} />
            <PreviewContentItem
              animateOnChange={false}
              title={
                <>
                  Mining fee rate
                  <Tooltip
                    arrow
                    placement="top"
                    compact
                    content={
                      "The mining fee rate is set to high priority to ensure that your bitcoin transaction is processed as quickly as possible."
                    }
                    data-testid="feeRateToolTip"
                  >
                    <HelpOutline className={"relative top-[-2px] ml-1 text-lg text-gray-400"} />
                  </Tooltip>
                </>
              }
              content={<FeeRateSummary feeRate={transactionFeeSatsVByte} />}
              containerStyles={itemContainerStyles}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <PreviewContentItem
              animateOnChange={false}
              title={"Mining fee total"}
              content={
                <FeeEstimateSummary
                  feeAmountInBtc={transactionFeeAmountBTC}
                  feeAmountInUsd={transactionFeeAmountUSD}
                />
              }
              containerStyles={itemContainerStyles}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <Divider className={`mb-1 mt-4 ${dividerColor}`} />
            <PreviewContentItem
              animateOnChange={false}
              title={"Receiving account"}
              content={
                receivingAccountType === ReceivingAccountType.CASH_BALANCE ? (
                  <ReceivingCashAccountSummary
                    cashBalanceUsd={cashBalanceUsd}
                    isIraCashAccount={isIraOrg}
                  />
                ) : (
                  <ReceivingBankAccountSummary
                    bankAccountLastFourDigits={bankAccountLastFourDigits}
                    bankAccountName={bankAccountName}
                  />
                )
              }
              containerStyles={itemContainerStyles}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <Button
              onClick={handleHideDetails}
              className={`mt-3 !text-sm !font-med !text-primary-600 hover:!text-gray-800`}
              variant="text"
            >
              Hide additional details
            </Button>
          </>
        </Collapse>
        {!isShowAdditionalDetails ? (
          <Button
            onClick={handleShowDetails}
            className={`!text-sm !font-med !text-primary-600 hover:!text-gray-800`}
            variant="text"
          >
            Show additional details
          </Button>
        ) : null}
      </div>
    </div>
  );
};
