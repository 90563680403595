import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { Button, Card, Loader } from "@unchained/component-library";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  resetSpendingWizardAction,
  setNextUnsignedSigRequestAction,
  setSelectedSigRequestAction,
} from "Actions/transactionActions/spendingActions";
import { PageTitle } from "Components/Layout/Header/Titles/PageTitle";
import { useNavigate } from "Components/Link";
import { BasicPage } from "Components/Pages";
import { Wizard } from "Components/Shared/wizard/Wizard";
import { useLocalStorageOrgUuid } from "Contexts/CurrentOrgUuidContext";
import { RootState } from "Reducers/index";
import { SpendingOperation } from "Reducers/transactionReducers/spendingReducer";
import { currentOrgSelector } from "Redux/selectors/accountSelectors";
import {
  spendingOperationSelector,
  spendingSigRequestsSelector,
} from "Redux/selectors/spendingSelectors";
import { UnifiedIADetailHeader } from "Routes/accounts/[accountId]/[tabName]/[itemId]/(components)/UnifiedIADetailHeader";
import { TransactionAPI, useGetAccount, useGetBtcPrice } from "Shared/api";
import { REQUEST_STATUS } from "Shared/api/api";
import { defaultGetProductOptions, useOrgProducts } from "Shared/api/v2/hooks/orgs";
import { triggerConfirmationModal } from "Shared/components/Modals";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { SigRequest } from "Specs/v1/getTransactionRequests/200";
import { hasPermission } from "Utils/acls";
import { useActions } from "Utils/hooks/useActions";
import {
  splitOperationDescription,
  splitOperationType,
  splitOperationTypePlural,
} from "Utils/operationTypes";
import { detableize } from "Utils/strings";

import NotFound from "../../errors/NotFound";
import { ApprovalStep } from "./ApprovalStep";
import { BroadcastStep } from "./BroadcastStep/BroadcastStep";
import { Operation } from "./OperationType";
import { SigningKeyList } from "./SigningStep/SigningKeyList";
import { SpendVerificationVideo } from "./SpendVerificationVideo";
import styles from "./SpendingWizard.module.scss";
import { SpendingWizardTitle } from "./SpendingWizardTitle";
import { SuspiciousOperationBanner } from "./SuspiciousOperationBanner";
import { VerificationStep } from "./VerificationStep";
import { getSpendingWizardUrlInfo, UrlInfo, useGetAccountSpendingData } from "./hooks";

export const postApprovalStates = [
  "ready_to_sign",
  "partially_signed",
  "fully_signed",
  "broadcasted",
];

interface SpendingWizardProps {
  txStatus: string;
  sigRequests: SigRequest[];
  signableSigRequests: SigRequest[];
  sigRequestsForOwnedKeys: SigRequest[];
  sigRequestsForViewableKeys: SigRequest[];
  txRequestState: string;
  currentOrg: CompleteOrg;
  operation: Operation;
  urlInfo: UrlInfo;
}

function sleep(ms = 500) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const SpendingWizardContent = (props: SpendingWizardProps) => {
  const {
    operation,
    txStatus,
    currentOrg,
    txRequestState,
    sigRequests,
    signableSigRequests,
    sigRequestsForOwnedKeys,
    sigRequestsForViewableKeys,
    urlInfo,
  } = props;

  const getSpendInfo = useGetAccountSpendingData();

  const { setSelectedSigRequest, setNextUnsignedSigRequest, resetSpendingWizard } = useActions({
    setSelectedSigRequestAction,
    setNextUnsignedSigRequestAction,
    resetSpendingWizardAction,
  });

  const [processingVideo, setProcessingVideo] = useState(false);
  const [hasSteppedPastApproval, setHasSteppedPastApproval] = useState<boolean | undefined>(
    undefined
  );
  const isMountedRef = useRef(true);
  const navigate = useNavigate();
  const { productType, productUuid, productTypePlural, operationUuid, isUia, accountId } = urlInfo;
  const { verification, verification_required } = operation;

  const approvalIsRequired = useMemo(() => {
    if (!currentOrg || !operation) return false;
    const delegateSignatureRequested =
      operation.btc_transaction_requests[0]?.signature_requests.some(
        signatureRequest => signatureRequest.account_key.role === "delegate"
      );

    const quorum = currentOrg.quorum_config?.min_approvals || 0;
    const applyToAllTxs = currentOrg.quorum_config?.apply_to_all_txs;

    return (
      quorum &&
      (applyToAllTxs || operation.unchained_signature_requested || delegateSignatureRequested)
    );
  }, [currentOrg, operation]);

  const approvalIsComplete = useMemo(() => {
    if (!operation || !currentOrg) return false;

    const { btc_transaction_requests } = operation;
    const txRequest = btc_transaction_requests[0];
    const quorum = currentOrg.quorum_config?.min_approvals || 0;
    const hasEnoughApprovals = Boolean(quorum && (txRequest?.approvals || []).length >= quorum);
    return (
      !approvalIsRequired || hasEnoughApprovals || postApprovalStates.includes(txRequest?.state)
    );
  }, [operation, currentOrg, approvalIsRequired]);

  const txRequestIsSigned = useMemo(() => {
    const { btc_transaction_requests } = operation;
    const txRequest = btc_transaction_requests[0];
    return Boolean(txRequest && ["fully_signed", "broadcasted"].includes(txRequest.state));
  }, [operation]);

  const allSteps = useMemo(() => {
    if (!operation || !currentOrg) return [];

    const showApprove = approvalIsRequired;
    const showVerify = operation.verification_required;

    return [
      {
        name: "Create",
        component: null,
        inWizard: false,
        inStepper: true,
        complete: true,
      },
      {
        name: "Verify",
        component: VerificationStep,
        inWizard: showVerify,
        inStepper: showVerify,
        complete:
          !["pending_processing", "pending_recording"].includes(operation.verification.state) ||
          currentOrg.type == "delegate",
      },
      {
        name: "Approve",
        component: ApprovalStep,
        inWizard: showApprove,
        inStepper: showApprove,
        complete: approvalIsComplete && hasSteppedPastApproval,
      },
      {
        name: "Sign",
        component: SigningKeyList,
        inWizard: true,
        inStepper: true,
        complete: txRequestIsSigned,
      },
      {
        name: "Broadcast",
        component: BroadcastStep,
        inWizard: true,
        inStepper: true,
        complete: false,
      },
    ];
  }, [
    operation,
    currentOrg,
    approvalIsRequired,
    approvalIsComplete,
    hasSteppedPastApproval,
    txRequestIsSigned,
  ]);

  const [stepperSteps, wizardSteps, stepperStep, wizardStep] = useMemo(() => {
    const withIndex = (step: any, index: number) => ({ ...step, index });
    const stepperSteps = allSteps.filter(step => step.inStepper).map(withIndex);
    const wizardSteps = allSteps.filter(step => step.inWizard).map(withIndex);
    const stepperStep = stepperSteps.find(step => !step.complete);
    const wizardStep = wizardSteps.find(step => !step.complete);
    return [stepperSteps, wizardSteps, stepperStep, wizardStep];
  }, [allSteps]);

  const spendInfoQuery = useGetAccountSpendingData();

  const updateWizard = useCallback(() => {
    if (signableSigRequests.length > 0) {
      setNextUnsignedSigRequest();
    } else if (sigRequestsForOwnedKeys.length > 0) {
      setSelectedSigRequest(sigRequestsForOwnedKeys[0]);
    } else if (sigRequestsForViewableKeys.length > 0) {
      setSelectedSigRequest(sigRequestsForViewableKeys[0]);
    } else if (sigRequests.length > 0) {
      setSelectedSigRequest(sigRequests[0]);
    }
    return Promise.resolve();
  }, [
    sigRequests,
    signableSigRequests,
    sigRequestsForOwnedKeys,
    sigRequestsForViewableKeys,
    setNextUnsignedSigRequest,
    setSelectedSigRequest,
  ]);

  const productPath = `/${productTypePlural}/${productUuid}`;
  const fullProductPath = isUia ? `/accounts/${accountId}${productPath}` : productPath;
  const updateWizardWithSelectedSigRequest = useCallback(async () => {
    try {
      // go back to main product page if operation doesn't exist or isn't active
      if (!operation || !Object.keys(operation).length || operation.state === "canceled") {
        navigate(fullProductPath);
      }

      updateWizard();
    } catch (e) {
      console.error(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txStatus, updateWizard, productTypePlural, productUuid, operation]);

  /**
   * Note: The `operation.type` is the "full" version, eg "vault_transaction".
   * Conversely, `operationType` is the "split" version, eg "transaction".
   * */
  const txName: string = detableize(operation.type).toLowerCase();

  const onCancelTransactionClick = useCallback(() => {
    const noun = txName;
    const cancelTransaction = async () => {
      const [productType, operationType] = splitOperationTypePlural(operation.type);

      await TransactionAPI.DeleteTransaction(
        productType,
        productUuid,
        operationUuid,
        operationType
      );
      navigate(fullProductPath);
    };

    triggerConfirmationModal({
      title: `Cancel ${noun}`,
      text: `Are you sure you want to cancel this ${noun}?`,
      confirmationText: "Confirm",
      onConfirm: cancelTransaction,
      cancellationText: "Back",
    });
  }, [txName, operation, productUuid, operationUuid, navigate, fullProductPath]);

  const hasVerification = useMemo(() => {
    return verification?.state !== "pending_recording";
  }, [verification]);

  const handleViewVerification = useCallback(() => {
    const title = capitalize(txName);
    AppModalManager.open(() => <SpendVerificationVideo title={title} />);
  }, [txName]);

  const showViewButton = useMemo(
    () =>
      verification &&
      hasVerification &&
      verification?.allowed_actions?.includes("view_verification"),
    [verification, hasVerification]
  );

  const showRecordButton = useMemo(
    () =>
      wizardStep?.name !== "Verify" &&
      !verification_required &&
      !hasVerification &&
      verification &&
      verification.allowed_actions.includes("record_verification") &&
      stepperStep?.index < wizardSteps.length,
    [wizardStep, verification_required, hasVerification, verification, stepperStep, wizardSteps]
  );

  const handleProcessVerification = useCallback(async () => {
    if (!isMountedRef.current) return;
    setProcessingVideo(true);
    spendInfoQuery.refetch();
    if (!hasVerification) {
      await sleep(1000);
      return handleProcessVerification();
    }
    if (isMountedRef.current) setProcessingVideo(false);
  }, [spendInfoQuery, hasVerification]);

  const handleRecordVerification = useCallback(() => {
    AppModalManager.open(() => (
      <VerificationStep
        isModal
        next={() => handleProcessVerification()}
        productType={productType}
      />
    ));
  }, [handleProcessVerification, productType]);

  const transactionIsDeletable = useMemo(() => {
    if (operation.state === "Pending confirmation") {
      return false;
    }

    if (
      (productType === "vault" &&
        ["vault_transaction", "batch_settlement", "vault_sale_transaction"].includes(
          operation.type
        )) ||
      productType === "loan"
    ) {
      return hasPermission((operation as SpendingOperation).allowed_actions, "cancel") as boolean;
    }

    return false;
  }, [operation, productType]);

  const txRequest = operation.btc_transaction_requests?.[0];

  useEffect(() => {
    updateWizardWithSelectedSigRequest();
    setHasSteppedPastApproval(approvalIsComplete);

    return () => {
      isMountedRef.current = false;
      resetSpendingWizard();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update the wizard if the tx updated
    getSpendInfo.refetch().then(() => {
      updateWizardWithSelectedSigRequest();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txStatus]);

  if (
    txStatus === "pending" ||
    !operation?.uuid ||
    !txRequest ||
    !currentOrg ||
    hasSteppedPastApproval === undefined
  ) {
    return <Loader className="h-screen" />;
  } else if (txStatus === "notFound") {
    return <NotFound />;
  }

  const { operationType: currentOperationType } = splitOperationDescription(
    (operation as SpendingOperation).description
  );

  return (
    <Grid container spacing={2} justifyContent="space-around">
      <Grid item xs={12} sm={11} md={12} classes={{ item: styles.cardContainer }}>
        <SuspiciousOperationBanner operation={operation}>
          <Card>
            <Grid container className={styles.signingWrapper}>
              <Grid item classes={{ item: styles.stepper }}>
                <Stepper activeStep={stepperStep?.index} alternativeLabel>
                  {stepperSteps.map(step => (
                    <Step key={step.name}>
                      <StepLabel
                        StepIconProps={{
                          classes: {
                            active: styles.icon__active,
                            completed: styles.icon__completed,
                          },
                        }}
                      >
                        {step.name}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>

              <Grid item classes={{ item: styles.content }}>
                <Wizard
                  onNextComponent={() => {
                    if (wizardStep?.name === "Approve" && !hasSteppedPastApproval) {
                      setHasSteppedPastApproval(true);
                    }
                  }}
                  manifest={wizardSteps}
                  startIndex={wizardStep?.index}
                />
              </Grid>

              <div className={styles.buttons}>
                {showViewButton && (
                  <Button color="secondary" onClick={handleViewVerification}>
                    View verification
                  </Button>
                )}
                {showRecordButton && (
                  <Button
                    color="secondary"
                    onClick={() => handleRecordVerification()}
                    disabled={processingVideo}
                  >
                    {processingVideo ? "Processing video" : "Record verification"}
                  </Button>
                )}
                {transactionIsDeletable && (
                  <Button
                    color="destructive"
                    onClick={onCancelTransactionClick}
                    disabled={txRequestState === "broadcasted"}
                  >
                    Cancel {currentOperationType.toLowerCase()}
                  </Button>
                )}
              </div>
            </Grid>
          </Card>
        </SuspiciousOperationBanner>
      </Grid>
    </Grid>
  );
};

const txStatusSelector = (state: RootState) => state.transaction.spending.status;

const SpendingWizardOg = (props: SpendingWizardProps) => {
  const { operation, urlInfo } = props;

  return (
    <BasicPage>
      <PageTitle className="mb-6">
        <SpendingWizardTitle
          productType={splitOperationType(operation.type)[0]}
          operationType={splitOperationType(operation.type)
            .map(word => capitalize(word))
            .join(" ")}
          uuid={urlInfo.productUuid}
          name={operation.vault?.name}
        />
      </PageTitle>
      <SpendingWizardContent {...props} />
    </BasicPage>
  );
};

const SpendingWizardUia = (props: SpendingWizardProps) => {
  const { urlInfo } = props;
  const { productTypePlural, productType, productUuid } = urlInfo;
  const ownedProductsQuery = useOrgProducts(urlInfo.accountId, defaultGetProductOptions);
  const allProductsQuery = useOrgProducts(urlInfo.accountId, {
    ...defaultGetProductOptions,
    vaultManagedGroup: undefined, // Allow users to see transactions for blinded shared vaults they can't directly access
  });
  const { data: ownedProducts } = ownedProductsQuery;
  const { data: allProducts } = allProductsQuery;

  if (!allProducts || !ownedProducts) return <Loader className="h-screen" />;

  const product = allProducts[productTypePlural as "vaults" | "loans"]?.find(
    product => product.uuid === productUuid
  );

  const isAnExpectedProductType = ["vaults", "loans"].includes(productTypePlural);

  // Make sure the URL hierarchy is correct, and the product exists within the account
  if (!product && isAnExpectedProductType) return <NotFound />;

  const productIsOwned = !!ownedProducts[productTypePlural as "vaults" | "loans"]?.find(
    product => product.uuid === productUuid
  );

  const backText = productIsOwned ? `Back to ${productType}` : `Back to ${productTypePlural}`;
  const backPath = productIsOwned
    ? `/accounts/${urlInfo.accountId}/${productTypePlural}/${productUuid}`
    : `/accounts/${urlInfo.accountId}/${productTypePlural}`;

  return (
    <div className="flex w-full flex-col items-center gap-12 p-4 md:px-12 md:py-12">
      <div className="w-full">
        <UnifiedIADetailHeader pathText={backText} path={backPath} />
      </div>
      <SpendingWizardContent {...props} />
    </div>
  );
};

export const SpendingWizard = () => {
  const { pathname } = useLocation();
  const urlInfo = getSpendingWizardUrlInfo(pathname);
  const { setValue: setCurrentOrgUuid } = useLocalStorageOrgUuid();
  const btcPrice = useGetBtcPrice();
  useGetAccountSpendingData();

  const currentOrg = useSelector(currentOrgSelector);
  const txStatus = useSelector(txStatusSelector);
  const spendingOperation = useSelector(spendingOperationSelector);
  const spendingSigRequests = useSelector(spendingSigRequestsSelector);

  const { resetSpendingWizard } = useActions({ resetSpendingWizardAction });

  // Make sure the current org is set to the correct account in UIA
  useEffect(() => {
    if (urlInfo.accountId && currentOrg?.uuid !== urlInfo.accountId) {
      setCurrentOrgUuid(urlInfo.accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlInfo.accountId, currentOrg?.uuid]);

  // Fetch data on mount; clear on unmount
  useEffect(() => {
    return () => {
      resetSpendingWizard();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !currentOrg?.uuid ||
    (urlInfo.accountId && currentOrg.uuid !== urlInfo.accountId) ||
    !btcPrice ||
    txStatus === REQUEST_STATUS.PENDING ||
    !spendingOperation?.operation ||
    !spendingSigRequests?.sigRequests
  ) {
    return <Loader className="h-screen w-full" />;
  }

  const { operation, txRequestState } = spendingOperation;

  // Combine all props from different sources
  const wizardProps: SpendingWizardProps = {
    currentOrg,
    operation: operation as Operation,
    txStatus,
    txRequestState,
    urlInfo,
    ...spendingSigRequests,
  };

  const Component = urlInfo.isUia ? SpendingWizardUia : SpendingWizardOg;

  return <Component {...wizardProps} />;
};

export default () => {
  useGetAccount();

  return <SpendingWizard />;
};
