import {
  ConfirmStreamingQuoteResponse,
  InitiateStreamingQuoteResponse,
} from "@unchained/api-specs/cosimo/v1/webSocketSellBitcoin/101";

import { BankAccountInterface } from "Components/bank_accounts/bankAccountUtils";
import { SET_TRADING_JWT } from "Contexts/BuyBitcoin";
import { Fee, SetTradingJwtAction } from "Contexts/BuyBitcoin/types";

import {
  DELETE_SELL_BITCOIN_IN_PROGRESS,
  SET_CLOSE_CONFIRMED_SALE,
  SET_CURRENT_SALE_UUID,
  SET_IS_SELL_BITCOIN_AVAILABLE,
  SET_IS_SHOW_CANCEL_MODAL,
  SET_SAVE_IN_PROGRESS_AND_CLOSE,
  SET_SELECTED_BANK_ACCOUNT,
  SET_SELECTED_KEYS,
  SET_SELL_AMOUNT,
  SET_SELL_BITCOIN_SOURCE,
  SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS,
  SET_SELL_BITCOIN_SUCCESS,
  SET_SELL_BITCOIN_WEB_SOCKET_STATUS,
  SET_SELL_INFO,
  SET_SELL_IN_PROGRESS_DATA,
  SET_STREAMING_QUOTE_SELL_RESPONSE,
  SET_RECEIVING_ACCOUNT_TYPE,
  SET_ORG_UUID,
} from "./SellBitcoinConstants";
import {
  DeleteSellBitcoinInProgress,
  OfflineStatus,
  SellAmountLimits,
  SellBitcoinWebSocketStatus,
  SellInProgressDataPayload,
  SellKey,
  SellSource,
  SellStatus,
  SellStreamingQuoteMessageTypes,
  SellStreamingQuoteStatus,
  SetCloseConfirmedSale,
  SetCurrentSaleUuid,
  SetIsSellTradingFeatureAvailable,
  SetIsShowCancelModal,
  SetSaveProgressAndClose,
  SetReceivingAccountType,
  SetSelectedBankAccount,
  SetSelectedKeys,
  SetSellBitcoinAmount,
  SetSellBitcoinOriginator,
  SetSellBitcoinStreamingQuoteStatus,
  SetSellBitcoinSuccess,
  SetSellBitcoinWebSocketStatus,
  SetSellInfo,
  SetSellInProgressData,
  SetStreamingQuoteSellResponse,
  SourceVault,
  streamingQuoteConfirmation,
  ReceivingAccountType,
  SetOrgUuid,
} from "./types";

export const setOrgUuid = (orgUuid: string, isIraOrg: boolean): SetOrgUuid => ({
  type: SET_ORG_UUID,
  payload: { orgUuid, isIraOrg },
});

export const setSellAmount = (amount: string): SetSellBitcoinAmount => ({
  type: SET_SELL_AMOUNT,
  payload: { amount },
});

export const setSellBitcoinWebSocketStatus = (
  status: SellBitcoinWebSocketStatus
): SetSellBitcoinWebSocketStatus => ({
  type: SET_SELL_BITCOIN_WEB_SOCKET_STATUS,
  payload: { status },
});

export const setTradingJwt = (jwt: string): SetTradingJwtAction => ({
  type: SET_TRADING_JWT,
  payload: { jwt },
});

export const setSellBitcoinStreamingQuoteStatus = (
  streamingQuoteStatus: SellStreamingQuoteStatus
): SetSellBitcoinStreamingQuoteStatus => ({
  type: SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS,
  payload: { streamingQuoteStatus },
});

export const setSellBitcionSelectedSource = (
  selectedSource: SellSource
): SetSellBitcoinOriginator => ({
  type: SET_SELL_BITCOIN_SOURCE,
  payload: { source: selectedSource },
});

export const setSelectedBankAccount = (
  selectedBankAccount: BankAccountInterface
): SetSelectedBankAccount => ({
  type: SET_SELECTED_BANK_ACCOUNT,
  payload: { selectedBankAccount },
});

export const setReceivingAccountType = (
  receivingAccountType: ReceivingAccountType
): SetReceivingAccountType => ({
  type: SET_RECEIVING_ACCOUNT_TYPE,
  payload: { receivingAccountType },
});

export const setSelectedKeys = (selectedKeys: SellKey[]): SetSelectedKeys => ({
  type: SET_SELECTED_KEYS,
  payload: { selectedKeys },
});

export const setIsShowCancelModal = (isShowCancelModal: boolean): SetIsShowCancelModal => ({
  type: SET_IS_SHOW_CANCEL_MODAL,
  payload: { isShowCancelModal },
});

export const setIsSellTradingFeatureAvailable = (
  isSellAvailable: boolean,
  isSaleInProgress: boolean
): SetIsSellTradingFeatureAvailable => ({
  type: SET_IS_SELL_BITCOIN_AVAILABLE,
  payload: { isSellAvailable, isSaleInProgress },
});

type setSellInfoProps = {
  bitcoinPrice: string;
  sources: {
    vaults: SourceVault[];
  };
  sellStatus: SellStatus;
  minimumSaleAmount: SellAmountLimits;
  maximumSaleAmount: SellAmountLimits;
  availableSaleAmountBTC: string;
  feeRates: Fee[];
  bankAccounts: BankAccountInterface[];
  currentSaleUuid: string;
  offlineStatus: OfflineStatus;
  sellLimitBTC: string;
  jwt: string;
  cashBalanceUsd: string;
};
export const setSellInfo = ({
  bitcoinPrice,
  sources,
  sellStatus,
  minimumSaleAmount,
  maximumSaleAmount,
  offlineStatus,
  feeRates,
  bankAccounts,
  currentSaleUuid,
  sellLimitBTC,
  jwt,
  availableSaleAmountBTC,
  cashBalanceUsd,
}: setSellInfoProps): SetSellInfo => ({
  type: SET_SELL_INFO,
  payload: {
    bitcoinPrice,
    sources,
    sellStatus,
    minimumSaleAmount,
    maximumSaleAmount,
    offlineStatus,
    feeRates,
    bankAccounts,
    currentSaleUuid,
    sellLimitBTC,
    jwt,
    availableSaleAmountBTC,
    cashBalanceUsd,
  },
});

export const setSellInProgressData = ({
  sellAmount,
  saleAmountBTC,
  saleAmountUSD,
  amountUSDTobeSentToClient,
  feeAmountUSD,
  transactionFeeSatsVByte,
  transactionFeeAmountUSD,
  transactionFeeAmountBTC,
  customerBTCChangeAddress,
  unchainedBTCReceiveAddress,
  bankName,
  bankLastFourDigits,
  selectedSource,
  selectedKeys,
  allKeys,
  transactionUuid,
  hasReviewedTx,
  txVideoVerification,
  hasConfirmedSignatures,
  hasBroadcastTx,
  receivingAccountType,
}: SellInProgressDataPayload): SetSellInProgressData => ({
  type: SET_SELL_IN_PROGRESS_DATA,
  payload: {
    sellAmount,
    saleAmountBTC,
    saleAmountUSD,
    amountUSDTobeSentToClient,
    feeAmountUSD,
    transactionFeeSatsVByte,
    transactionFeeAmountUSD,
    transactionFeeAmountBTC,
    customerBTCChangeAddress,
    unchainedBTCReceiveAddress,
    bankName,
    bankLastFourDigits,
    selectedSource,
    selectedKeys,
    allKeys,
    transactionUuid,
    hasReviewedTx,
    txVideoVerification,
    hasConfirmedSignatures,
    hasBroadcastTx,
    receivingAccountType,
  },
});

export const setCurrentSaleUuid = (currentSaleUuid: string): SetCurrentSaleUuid => ({
  type: SET_CURRENT_SALE_UUID,
  payload: {
    currentSaleUuid,
  },
});

export const deleteSellBitcoinInProgress = (): DeleteSellBitcoinInProgress => ({
  type: DELETE_SELL_BITCOIN_IN_PROGRESS,
  payload: null,
});

export const handleSellBitcoinWebSocketMessages = (
  response: InitiateStreamingQuoteResponse | ConfirmStreamingQuoteResponse,
  dispatch
) => {
  if (response.messageType === SellStreamingQuoteMessageTypes.STREAMING_QUOTE_SELL_RESPONSE) {
    const initiateStreamingQuoteResponse = response as InitiateStreamingQuoteResponse;

    dispatch(setStreamingQuoteSellResponse(initiateStreamingQuoteResponse));

    setTimeout(() => {
      // Transition from SellStreamingQuoteStatus.LOADING_INCOMING_STREAMING_QUOTE state to SellStreamingQuoteStatus.ACTIVE_STREAMING_QUOTE
      // after half a second to allow for a grace period when transitioning from the old quote to the
      // new one.
      dispatch(setSellBitcoinStreamingQuoteStatus(SellStreamingQuoteStatus.ACTIVE_STREAMING_QUOTE));
    }, 500);
  } else if (response.messageType === SellStreamingQuoteMessageTypes.CONFIRM_SELL_RESPONSE) {
    const confirmSellResponse = response as ConfirmStreamingQuoteResponse;
    if (confirmSellResponse.tradeStatus === streamingQuoteConfirmation.ACCEPTED) {
      dispatch(setSellBitcoinSuccess(confirmSellResponse));
    } else {
      dispatch(
        setSellBitcoinStreamingQuoteStatus(
          SellStreamingQuoteStatus.SERVER_DENIED_THE_ACCEPTED_STREAMING_QUOTE
        )
      );
    }
  }
};

export const setStreamingQuoteSellResponse = (
  streamingQuote: InitiateStreamingQuoteResponse
): SetStreamingQuoteSellResponse => ({
  type: SET_STREAMING_QUOTE_SELL_RESPONSE,
  payload: streamingQuote,
});

export const setSellBitcoinSuccess = (
  confirmedSell: ConfirmStreamingQuoteResponse
): SetSellBitcoinSuccess => ({
  type: SET_SELL_BITCOIN_SUCCESS,
  payload: confirmedSell,
});

export const setSaveProcessAndClose = (): SetSaveProgressAndClose => ({
  type: SET_SAVE_IN_PROGRESS_AND_CLOSE,
  payload: null,
});

export const setCloseConfirmedSale = (): SetCloseConfirmedSale => ({
  type: SET_CLOSE_CONFIRMED_SALE,
  payload: null,
});
