import { keyBy } from "lodash";
import { useQueries, useQuery, UseQueryOptions } from "react-query";

import { amountsFromProductQuery } from "Routes/accounts/(utils)/amountsFromProductQuery";
import { useGetBtcPrice } from "Shared/api";
import { CheckIfOrgClosureIsAllowed200, GetOrgProducts200 } from "Specs/v2";
import { OrgItem, WorkspaceOrgMemberSettings } from "Specs/v2/components";
import { GetOrgProductsQueryParams } from "Specs/v2/getOrgProducts/params/query";

import { OrgAPI } from "..";
import { useWorkspaceData } from "./workspaces";

export const orgV2QueryKeys = {
  all: ["orgs-v2"] as const,
  closureAllowed: (orgId: string) => [...orgV2QueryKeys.all, orgId],
  get: (invoiceId: string) => [...orgV2QueryKeys.all, invoiceId],
  getProducts: (orgId: string, options: GetOrgProductsQueryParams = {}) => [
    ...orgV2QueryKeys.all,
    "products",
    orgId,
    options,
  ],
} as const;

export const useClosureAllowed = (
  orgId: string,
  options?: UseQueryOptions<CheckIfOrgClosureIsAllowed200>
) =>
  useQuery<CheckIfOrgClosureIsAllowed200>(
    orgV2QueryKeys.closureAllowed(orgId),
    () => OrgAPI.GetClosureAllowed(orgId),
    options
  );

/**
 * By default, only live vaults and loans are returned, and only "owned" ones (not shared).
 */
export const defaultGetProductOptions: GetOrgProductsQueryParams = {
  vaultState: "live",
  loanState: "live",
  vaultManagedGroup: ["owned"],
};

/**
 * By default, only live vaults and loans are returned, and only "owned" ones (not shared).
 * If you want to look at all products, pass an empty set of productsOptions.
 */
export const useOrgProducts = (
  orgId: string,
  productsOptions: GetOrgProductsQueryParams = defaultGetProductOptions,
  options: UseQueryOptions<GetOrgProducts200> = {}
) => {
  return useQuery<GetOrgProducts200>(
    orgV2QueryKeys.getProducts(orgId, productsOptions),
    () => OrgAPI.GetOrgProducts(orgId, productsOptions),
    { enabled: !!orgId, staleTime: 20 * 1000, ...options }
  );
};

/**
 * By default, only live vaults and loans are returned, and only "owned" ones (not shared).
 * If you want to look at all products, pass an empty set of productsOptions.
 */
export const useProductsQueries = (
  orgs: OrgItem[],
  orgMemberSettings: WorkspaceOrgMemberSettings[],
  productsOptions: GetOrgProductsQueryParams = defaultGetProductOptions,
  queryOpts: UseQueryOptions<GetOrgProducts200> = {}
) => {
  const settingsMap = keyBy(orgMemberSettings, "orgId");
  return useQueries(
    orgs.map(org => ({
      queryKey: orgV2QueryKeys.getProducts(org.id, productsOptions),
      queryFn: () => OrgAPI.GetOrgProducts(org.id, productsOptions),
      enabled: !!org.id,
      ...queryOpts,
      // By default, only re-request if 20s have passed
      staleTime: queryOpts.staleTime || 20 * 1000,
      select: (data: GetOrgProducts200 & { rollup: boolean }) => ({
        ...data,
        orgId: org.id,
        rollup: settingsMap[org.id]?.rollupBalances,
      }),
    }))
  );
};

/** By default, this includes all products, in all states, owned and shared. */
export const useWorkspaceHasAnyProducts = (
  productOptions: GetOrgProductsQueryParams = defaultGetProductOptions,
  queryOpts: UseQueryOptions<GetOrgProducts200> = {}
) => {
  const { data } = useWorkspaceData();
  const orgs = data?.orgs ?? [];

  const productsQueries = useProductsQueries(orgs, [], productOptions, queryOpts);

  const productTypes = [
    "vaults",
    "loans",
    "cashBalances",
    "pockets",
  ] as (keyof GetOrgProducts200)[];

  const isLoading = productsQueries.some(query => query.isLoading);
  const hasAnyProducts = productsQueries.some(query =>
    productTypes.some(type => query.data?.[type]?.length)
  );

  return { isLoading, hasAnyProducts };
};

/**
 * By default, only live vaults and loans are included, and only "owned" ones (not shared).
 * If you want to look at all products, pass an empty set of productsOptions.
 */
export const useWorkspaceProductBalances = (
  orgs: OrgItem[],
  orgMemberSettings: WorkspaceOrgMemberSettings[],
  productsOptions: GetOrgProductsQueryParams = defaultGetProductOptions,
  queryOpts: UseQueryOptions<GetOrgProducts200> = {}
) => {
  const price = useGetBtcPrice();
  const productsQueries = useProductsQueries(orgs, orgMemberSettings, productsOptions, queryOpts);

  let [bitcoin, cash, total] = [0, 0, 0];
  productsQueries.forEach(query => {
    const amounts = amountsFromProductQuery(query, price);
    bitcoin += amounts.bitcoin;
    cash += amounts.cash;
    total += amounts.total;
  });

  return { bitcoin, cash, total, isLoading: productsQueries.some(query => query.isLoading) };
};
