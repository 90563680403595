import { Navigate, useLocation, useParams } from "react-router-dom";

/**
 * Given a URL with dynamic parameters, fills them from params and navigates to the new URL
 *
 * Eg, redirects from `/orgs/:uuid/admin` to `/admin/orgs/:uuid`
 *
 * @example
 *
 * new RouteGroup().children({
 *   // Redirects from `/orgs/12345` to `/admin/orgs/12345`
 *   "/orgs/:uuid": <Remap to="/admin/orgs/:uuid" />
 * })
 */
export const Remap = ({ to }: { to: string }) => {
  const params = useParams();

  const filledPath = to.replace(/:[^/]+/g, match => params[match.slice(1)]);

  return <Navigate to={filledPath} />;
};

/**
 * Component that swaps one top-level route for another, preserving the rest of the path.
 *
 * For example, redirects from `/account_keys/keyUUID/rekey` to `/keys/keyUUID/rekey` by swapping `/account_keys` with `/keys`.
 *
 * @param props.to - The new top-level route to redirect to
 * @param props.from - The current top-level route to replace
 *
 * @example
 *
 * "/account_keys": new RouteGroup()
 *  .childAuthorizers({
 *    requireOrgRepayment,
 *    blockUIA,
 *    blockUIAReplacement: () => <SwapTopLevelRoute to="/keys" from="/account_keys" />,
 *  })
 *  .children({
 *    "/": new Route(Comps.AccountKeysList),
 *    ...rest of child routes
 *  })
 */

export const SwapRoute = ({ to, from }: { to: string; from: string }) => {
  const { pathname } = useLocation();
  return <Navigate to={pathname.replace(from, to)} />;
};
