import { useMemo } from "react";

import { Popover } from "@mui/material";
import { Icons } from "@unchained/component-library";

import { useV2AggregatedNotifications } from "Shared/api/v2/hooks/notifications";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";
import { OrgItem } from "Specs/v2/components";
import { usePopover } from "Utils/hooks";

import { NormalizedNotification, NotificationRow, V1Notification } from "./NotificationRow";
import { getV1NotificationDetails } from "./getV1NotificationDetails";
import { getV2NotificationDetails, V2Notification } from "./getV2NotificationDetails";

const NotificationPopover = ({
  notifications,
  close,
}: {
  notifications: NormalizedNotification[];
  close: () => void;
}) => {
  const sorted = notifications.sort(
    (a: NormalizedNotification, b: NormalizedNotification) =>
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  return (
    <div
      className="flex max-w-[400px] flex-col gap-4 rounded-md pb-2 pt-4"
      data-testid="notifications-popover"
    >
      <h2 className="px-4">Notifications</h2>
      <div className="relative flex max-h-[450px] flex-col overflow-y-auto">
        {sorted.map((notification, index) => (
          <NotificationRow
            key={`${notification.text}-${index}`}
            notification={notification}
            close={close}
          />
        ))}
      </div>
    </div>
  );
};

const normalizeV1Notification = (org: OrgItem, n: V1Notification) => {
  return {
    ...getV1NotificationDetails(org?.id, n),
    org,
    timestamp: new Date().toISOString(),
    original: n,
  } as NormalizedNotification;
};

const normalizeV2Notification = (n: V2Notification, orgs: OrgItem[]) => {
  const org =
    n.targetType === "org"
      ? orgs.find(o => o.id === n.targetUuid)
      : n.targetType === "user"
      ? orgs.find(o => o.accountType === "individual")
      : undefined;

  return {
    ...getV2NotificationDetails(n),
    org,
    timestamp: new Date(n.notification.createdAt).toISOString(),
    original: n,
  };
};

export const UIANotifications = withWorkspaceData(({ orgs }) => {
  const { data } = useV2AggregatedNotifications();
  const popover = usePopover();

  const { notifications = [], userActions, orgActions: orgActionsGroups } = data ?? {};

  const allNotifications: NormalizedNotification[] = useMemo(() => {
    let v1Notifications: NormalizedNotification[] = [];
    orgActionsGroups?.forEach(orgActionSet => {
      const { orgUuid, ...rest } = orgActionSet;
      const org = orgs.find(o => o.id === orgUuid);
      const flattened = Object.values(rest).flat() as V1Notification[];

      v1Notifications.push(...flattened.map(n => normalizeV1Notification(org, n)));
    });

    const userActionsNotifications = userActions?.org?.map((n: V1Notification) => {
      const org = orgs.find(o => o.id === n.uuid);
      return normalizeV1Notification(org, n);
    });

    v1Notifications.push(...(userActionsNotifications || []));

    const v2Notifications = notifications.map(n => normalizeV2Notification(n, orgs));

    return [...v1Notifications, ...v2Notifications].filter(n => n?.text);
  }, [notifications, orgs, userActions, orgActionsGroups]);

  return (
    <div className="absolute right-4 top-1">
      <div
        ref={popover.triggerRef}
        onClick={allNotifications.length ? popover.setOpen : undefined}
        className="flex cursor-pointer flex-col items-center justify-center rounded-full border border-gray-200 bg-white p-2 text-primary-600 hover:bg-gray-50"
      >
        <Icons.Bell size="sm" />
      </div>
      <Popover
        open={popover.isOpen}
        anchorEl={popover.popoverRef}
        onClose={popover.setClosed}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        style={{ left: -12, top: -12 }} // Position the popover near the bottom right
      >
        <NotificationPopover notifications={allNotifications} close={popover.setClosed} />
      </Popover>
      {allNotifications.length ? (
        <div className="absolute -right-1 -top-1 flex h-4 min-w-[1rem] flex-col items-center justify-center rounded-full bg-red-500 px-1 text-xs text-white">
          {allNotifications.length > 9 ? "9+" : allNotifications.length}
        </div>
      ) : null}
    </div>
  );
});
