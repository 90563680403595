import { FC } from "react";

import { Loader } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { useSwitchCurrentOrg } from "Components/Layout/Nav/Sidebar/OldSidebar/hooks";
import { comingFromLoginSelector } from "Redux/selectors/navigation";
import { GetAccount } from "Shared/api";
import { GetIraOrg200 } from "Specs/v1";
import {
  AccountUser,
  CompleteOrg,
  CompleteOrgAccountTypeEnum,
  CompleteOrgAllowedActionsEnum,
  CompleteOrgTypeEnum,
} from "Specs/v1/getAccount/200";
import { GetUserWorkspaceData200 } from "Specs/v2";
import { OrgItem } from "Specs/v2/components";
import { personalOrgBasicInfoCompleteStates, postOnboardingStates } from "Utils/orgState";

import { LockedForPayment } from "../LockedForPayment/LockedForPayment";
import { NavigateBack, NavigateBackWithToast, NavigateWithToast } from "./NavigateWithToast";
import { isOrgAccessible } from "./orgAccessibility";

type OrgFeature = keyof CompleteOrg["all_features"];
type UserFeature = keyof AccountUser["all_features"];

type RequestType = "workspace" | "account" | "ira";

type AuthorizerName =
  | "blockUIA"
  | "requireOrgAllowedActions"
  | "requireOneOfOrgAccountTypes"
  | "requireOneOfOrgTypes"
  | "requireOrgRepayment"
  | "requireBasicInfo"
  | "requirePostOnboardingOrg"
  | "requireAdmin"
  | "blockAdmin"
  | "requireUIA"
  | "requireAllOrgFeatures"
  | "requireAllUserFeatures"
  | "requireIraConsent"
  | "requireOrgInUrl"
  // Needed because of old biz members who didn't complete onboarding
  | "requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg"
  | "requireLiveOrg"
  | "requireNotOrgState";

export type AuthorizeProps = {
  workspace?: GetUserWorkspaceData200;
  account?: GetAccount;
  ira?: GetIraOrg200["org"];
  /**
   * The ID of the org to check for blocking.
   * Derived from the accountId in params, or, if not found,
   * from the current org in the account.
   * */
  orgId: string;
  /**
   * The "org" object to check for blocking.
   * An OrgItem-like object derived from either the workspace.org found by the orgId,
   * or the account.currentOrg */
  org?: Pick<OrgItem, "id" | "accountType" | "state" | "type" | "allowedActions">;
  params: Record<string, string>;
  queryParams: Record<string, string>;
};

export type AuthorizerTemplate<Req = unknown> = {
  name: AuthorizerName;
  requiredData: RequestType[];
  shouldBlock: (args: AuthorizeProps, req: Req) => boolean;
  blockBecause: (req: Req) => string;
  DefaultReplacement: FC<AuthorizeProps>;
};

/**
 * NOTE: Authorizers are applied in the order defined in the final authorizerTemplate array.
 * This means that more "basic" authorizers should be defined before more "specific" authorizers.
 *
 * For example, we define `requireBasicInfo` before `requireUIA`, because if both are applied to the same route
 * we should send the user to onboarding *instead of* sending them to the non-UIA routes. The onboarding requirement
 * comes first, and takes precedence.
 */

const workspaceOnlyAuthorizerTemplates = [
  {
    name: "requireBasicInfo",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ workspace: { orgs, user } }, req: never) => {
      if (user.isUnchainedAdmin) return false;
      const personalOrg = orgs.find(o => o.accountType === "individual");
      return !personalOrgBasicInfoCompleteStates.includes(personalOrg?.state);
    },
    blockBecause: (req: never) => "Personal org basic info not complete.",
    DefaultReplacement: () => (
      <NavigateBackWithToast
        toast={{
          title: "Your basic info is not complete",
          description: "Please complete missing basic info to continue.",
          showClientReportLink: false,
        }}
        homeOverwrite="/onboard"
      />
    ),
  },
  {
    name: "requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ workspace: { user, orgs } }, req: never) => {
      if (user.isUnchainedAdmin || !orgs.every(o => o.type === "client")) return false;
      const personalOrg = orgs.find(o => o.accountType === "individual");
      const hasOtherLiveOrg = orgs.some(o => o.accountType !== "individual" && o.state === "live");

      return !personalOrgBasicInfoCompleteStates.includes(personalOrg?.state) && !hasOtherLiveOrg;
    },
    blockBecause: (req: never) => "Basic profile incomplete, and not a member of another live org.",
    DefaultReplacement: () => {
      const comingFromLogin = useSelector(comingFromLoginSelector);
      return (
        <NavigateWithToast
          to="/onboard"
          toast={
            comingFromLogin
              ? false
              : {
                  title: "Basic profile incomplete",
                  description: "Please complete your basic profile to continue.",
                  showClientReportLink: false,
                }
          }
        />
      );
    },
  },
  {
    name: "requirePostOnboardingOrg",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org, workspace: { user } }, req: never) => {
      if (user.isUnchainedAdmin) return false;
      return org?.accountType === "individual"
        ? !personalOrgBasicInfoCompleteStates.includes(org?.state)
        : !postOnboardingStates.includes(org?.state);
    },
    blockBecause: (req: never) => "Org is not in a post-onboarding state.",
    DefaultReplacement: ({ workspace: { orgs, user } }) => {
      const comingFromLogin = useSelector(comingFromLoginSelector);
      const switchCurrentOrg = useSwitchCurrentOrg();

      const accessibleOrgs = orgs.filter(o => isOrgAccessible(o, orgs));

      if (accessibleOrgs.length === 0) {
        // This is the post-signup-then-login flow (in addition to others).
        // Best to err on the side of less noisiness and not show the toast here.
        const toast = comingFromLogin
          ? false
          : {
              title: "Your basic info is not complete",
              description: "Please complete missing basic info to continue.",
              showClientReportLink: false,
            };
        return <NavigateBackWithToast toast={toast} homeOverwrite="/onboard" />;
      }

      const otherOrgId = accessibleOrgs[0].id;

      if (user.canViewUnifiedIA) {
        return <NavigateBackWithToast homeOverwrite={`/accounts/${otherOrgId}`} toast={false} />;
      } else {
        // Switch current org to the first active ira or trust
        switchCurrentOrg(otherOrgId);
        // The component should re-render presently, with new org data, so return a short-lived Loader
        return <Loader className="h-screen" />;
      }
    },
  },
  {
    name: "blockUIA",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ workspace: { user } }, req: never) => user.canViewUnifiedIA,
    blockBecause: (req: never) => "UIA-enabled users not allowed.",
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
  {
    name: "requireOrgAllowedActions",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org }, req: CompleteOrgAllowedActionsEnum[]) =>
      !req.every(a => org?.allowedActions?.includes(a)),
    blockBecause: (req: CompleteOrgAllowedActionsEnum[]) =>
      `Org does not have all required allowed actions: ${req.join(", ")}.`,
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
  {
    name: "requireOneOfOrgAccountTypes",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org }, req: CompleteOrgAccountTypeEnum[]) => !req.includes(org?.accountType),
    blockBecause: (req: CompleteOrgAccountTypeEnum[]) =>
      `Org is not of the required account type: ${req.join(", ")}.`,
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
  {
    name: "requireOneOfOrgTypes",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org }, req: CompleteOrgTypeEnum[]) => !req.includes(org?.type),
    blockBecause: (req: CompleteOrgTypeEnum[]) =>
      `Org is not of the required type: ${req.join(", ")}.`,
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
  {
    name: "requireOrgRepayment",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org }, req: never) => org?.state === "pending_payment_renewal",
    blockBecause: (req: never) => "Org is pending payment renewal.",
    DefaultReplacement: ({ orgId }) => <LockedForPayment orgUuid={orgId} />,
  },
  {
    name: "requireAdmin",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ workspace: { user } }, req: never) => !user.isUnchainedAdmin,
    blockBecause: (req: never) => "Unchained admin required.",
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
  {
    name: "blockAdmin",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ workspace: { user } }, req: never) => user.isUnchainedAdmin,
    blockBecause: (req: never) => "Unchained admin not allowed.",
    DefaultReplacement: (props: AuthorizeProps) => (
      <NavigateBackWithToast {...props} toast={false} />
    ),
  },
  {
    name: "requireUIA",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ workspace: { user } }, req: never) => !user.canViewUnifiedIA,
    blockBecause: (req: never) => "UIA-enabled users not allowed.",
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
  {
    name: "requireLiveOrg",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org }, req: never) => org?.state !== "live",
    blockBecause: (req: never) => "Org is not live.",
    DefaultReplacement: ({ org }) => {
      if (org?.state === "pending_payment_renewal") {
        return <LockedForPayment orgUuid={org?.id} />;
      } else {
        return <NavigateBackWithToast homeOverwrite={`/onboard/${org?.id}`} />;
      }
    },
  },
  {
    name: "requireOrgInUrl",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org, params }, req: never) =>
      !(params.accountId && params.accountId === org?.id),
    blockBecause: (req: never) => "Org not found.",
    DefaultReplacement: () => (
      <NavigateBackWithToast
        toast={{
          title: "Account not found",
          description: "Could not find the account you were looking for.",
        }}
      />
    ),
  },
  {
    name: "requireNotOrgState",
    requiredData: ["workspace"] as RequestType[],
    shouldBlock: ({ org }, req: OrgItem["state"][]) => req.includes(org?.state),
    blockBecause: (req: OrgItem["state"][]) => `Org is in a disallowed state: ${req.join(", ")}.`,
    DefaultReplacement: ({ org }) => {
      switch (org?.state) {
        case "pending_approval":
          return (
            <NavigateWithToast
              to={`/onboard/${org?.id}`}
              toast={{
                title: "Account is pending approval",
                description: "You can access it once it's approved.",
                showClientReportLink: false,
              }}
            />
          );
        default:
          return <NavigateBack />;
      }
    },
  },
] satisfies readonly AuthorizerTemplate[];

const accountOnlyAuthorizerTemplates = [
  {
    name: "requireAllOrgFeatures",
    requiredData: ["account"] as RequestType[],
    shouldBlock: ({ account: { currentOrg } }, req: OrgFeature[]) =>
      !req.every(r => currentOrg?.all_features?.[r]),
    blockBecause: (req: OrgFeature[]) =>
      `Org does not have all required features: ${req.join(", ")}.`,
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
  {
    name: "requireAllUserFeatures",
    requiredData: ["account"] as RequestType[],
    shouldBlock: ({ account: { user } }, req: UserFeature[]) =>
      !req.every(r => user?.all_features?.[r]),
    blockBecause: (req: UserFeature[]) =>
      `User does not have all required features: ${req.join(", ")}.`,
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
] satisfies readonly AuthorizerTemplate[];

const iraAndWorkspaceAuthorizerTemplates = [
  {
    name: "requireIraConsent",
    requiredData: ["workspace", "ira"] as RequestType[],
    shouldBlock: ({ org, ira }, req: never) =>
      org?.accountType === "ira" &&
      !(ira?.iraPlan?.fortisAgreementAcceptedAt || ira?.iraPlan?.fortisAgreementDeclinedAt),
    blockBecause: (req: never) => "IRA consent not accepted or denied.",
    DefaultReplacement: () => <NavigateBackWithToast />,
  },
] satisfies readonly AuthorizerTemplate[];

/**
 * These are the "available" authorizers. Each requires some data to be passed in, and
 * will block if the data does not match the requirement.
 *
 * They specify their "requiredData" (required API call) so that the Authorize component can
 * only fetch the data required (and no more), to block efficiently.
 *
 * Each authorizer has a "shouldBlock" function, which takes the relevant fetched data and an optional
 * "req" (requirements) arg, which must be typed.
 *
 * If the req is defined for this authorizer, it will be required in the authorizer props.
 * Otherwise, the authorizer will be applied as a boolean.
 *
 * Each authorizer has a default replacement that will be used if the authorizer is hit.
 * The default replacement for most cases is to navigate back/home with a 404 style toast.
 *
 * Just follow the basic pattern to add a new authorizer type
 */
export const authorizerTemplates = [
  ...workspaceOnlyAuthorizerTemplates,
  ...accountOnlyAuthorizerTemplates,
  ...iraAndWorkspaceAuthorizerTemplates,
] as const satisfies readonly AuthorizerTemplate[];

/**
 * Groups of authorizers that don't make sense to be used together.
 *
 * Used in routing logic to determine if a route's authorization is improper.
 */
export const mutuallyExclusiveAuthorizers = [
  // All of these are proxies for org.type
  ["blockAdmin", "requireAdmin", "requireOneOfOrgTypes"],
  ["requireUIA", "blockUIA"],
] as AuthorizerName[][];
