import { isEmpty, omit, pick } from "lodash";
import { useLocation } from "react-router-dom";

import { getConfig } from "./config";

export function podiumURL(path) {
  try {
    return getConfig("podium_base_url") + path;
  } catch {
    return "https://unchained.com" + path;
  }
}

export const queryParamsFromSearch = (search: string) => {
  const query = {};
  const searchParams = new URLSearchParams(search);
  for (const [k, v] of searchParams) {
    if (query[k] === undefined) {
      query[k] = v;
    } else {
      query[k] = query[k] instanceof Array ? [...query[k], v] : [query[k], v];
    }
  }
  return query;
};

export const queryParams = location => {
  let query: Record<string, string> = {};
  if (location.search) {
    query = queryParamsFromSearch(location.search);
  }
  return query as Record<string, string>;
};

/**
 * Determine if a given path is an absolute URL or just an internal path.
 * From: https://stackoverflow.com/a/19709846/1408935
 */
export const isAbsoluteURL = path => {
  const r = /^(?:[a-z]+:)?\/\//i;
  return r.test(path);
};

type QueryParam = string | number | boolean;
type QueryParams = Record<string, QueryParam | QueryParam[]>;
/**
 * Given an object of query parameters, convert into a query string for a url
 */
export const createQueryString = <T extends QueryParams = QueryParams>(
  params: T = {} as T,
  explodeArrayParams = true
): string =>
  Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(key => {
      const value = params[key];
      if (Array.isArray(value)) {
        if (explodeArrayParams) {
          // Create separate key=value pairs for each array item
          return value
            .map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
            .join("&");
        } else {
          // Join array values with commas
          return `${encodeURIComponent(key)}=${value.map(encodeURIComponent).join(",")}`;
        }
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

const qsIsEmpty = (val: string | number | (string | number)[]) => {
  if (val instanceof Array) return isEmpty(val);
  if (typeof val === "string") return isEmpty(val);
  return val === undefined;
};

export type Params = Record<string, number | string | (number | string)[]>;

/**
 * Exploded query params are an alternate way to encode array query params in the URL.
 * We follow this pattern (key=firstval&key=secondVal) for some endpoints.
 *  */
const stringifyQSExploded = (queryParams: Params) => {
  return Object.keys(queryParams)
    .map(key =>
      queryParams[key] instanceof Array
        ? (queryParams[key] as (number | string)[]).map(val => `${key}=${val}`).join("&")
        : `${key}=${queryParams[key]}`
    )
    .join("&");
};

/**
 * Stringify in list format (key=value1,value2)
 *  */
const stringifyQs = (queryParams: Params) => {
  return Object.keys(queryParams)
    .map(key =>
      queryParams[key] instanceof Array
        ? `${key}=${(queryParams[key] as (number | string)[]).join(",")}`
        : `${key}=${queryParams[key]}`
    )
    .join("&");
};

/** Clears out empty params */
const prepareQueryParams = (queryParams: Params) => {
  return Object.keys(queryParams)
    .filter(key => !qsIsEmpty(queryParams[key]))
    .reduce((newObj, key) => ({ ...newObj, [key]: queryParams[key] }), {});
};

/** Clear out empty params and stringify them, either in:
 * - list format: key=value1,value2 (default)
 * - exploded/repeat format: key=value1&key=value2
 */
export const stringifyQueryParams = (params: Params, explode?: boolean) =>
  (explode ? stringifyQSExploded : stringifyQs)(prepareQueryParams(params));

/** Returns the query param object */
export const useQueryParams = () => {
  const location = useLocation();
  return queryParams(location);
};

export const useClearQueryParams = () => {
  const currentParams = useQueryParams();

  return (...params: string[]) => {
    const newParams = omit(currentParams, params);
    const newUrl = [location.pathname, createQueryString(newParams)].filter(Boolean).join("?");
    window.history.replaceState({}, "", newUrl);
  };
};

/** Given an array of query param keys, removes them from the URL and returns an object with their values. */
export const useAndRemoveQueryParams = <T extends string>(...keys: T[]) => {
  const params = useQueryParams();
  const clearQueryParams = useClearQueryParams();
  const picked = pick(params, keys);

  // Remove query param from URL without refresh
  clearQueryParams(...keys);

  return picked;
};
