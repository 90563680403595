import { useEffect } from "react";

import { Icons, Modal, ModalContent, ModalFooter, ModalHeader } from "@unchained/component-library";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { replaceAccountCurrentOrgAction } from "Actions/accountActions/orgActions";
import { Link } from "Components/Link";
import OrgSelect from "Components/TradingDesk/components/OrgSelect";
import { SellBitcoinStepperWizard } from "Components/TradingDesk/sell/SellBitcoinStepperWizard";
import { TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE } from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { setOrgUuid } from "Contexts/SellBitcoin/SellBitcoinActions";
import {
  useSellBitcoinDispatch,
  useSellBitcoinStore,
} from "Contexts/SellBitcoin/sellBitcoinContext";
import { SellStatus } from "Contexts/SellBitcoin/types";
import {
  tradesKeys,
  TradingAPI,
  useGetAccount,
  useTradingSellInfo,
  useTradingStatus,
} from "Shared/api";
import { useGetOrg } from "Shared/api/hooks/orgs";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { AppModalManager } from "Shared/components/Modals";
import { getConfig } from "Utils/config";
import { useActions } from "Utils/hooks";
import { findAndReplaceTextWithComponent } from "Utils/strings";

const email = getConfig("email.help");
const findAndReplaceClientServicesEmailTextWithLink = text => {
  const emailComponent = (
    <Link key={"clientServicesEmail"} to={`mailto:${email}`}>
      {email}
    </Link>
  );

  return findAndReplaceTextWithComponent(emailComponent, text, email);
};

export function HelpText({
  sellStatus,
  offlineStatus,
  isSellingFeatureAvailable,
  onboardingStatus,
  orgUuid,
  isIra,
}) {
  const shouldShowContinueOnboarding = () => {
    if (!onboardingStatus) return false;
    if (!onboardingStatus.hasVault) return true;
    if (onboardingStatus.isTierThreeProfile !== TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE)
      return true;
    if (
      !isIra &&
      onboardingStatus.isBankAccountOnFile !== TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE
    )
      return true;
    return false;
  };

  let title = offlineStatus?.title;
  let tooltipContent = (
    <span>
      {offlineStatus?.description
        ? findAndReplaceClientServicesEmailTextWithLink(offlineStatus.description)
        : ""}
    </span>
  );

  if (!isSellingFeatureAvailable) {
    if (shouldShowContinueOnboarding()) {
      return (
        <div className="-ml-3">
          <Link
            to={`/accounts/${orgUuid}/`}
            className="font-semi text-primary-600"
            onClick={AppModalManager.close}
          >
            Complete
          </Link>{" "}
          the required account setup steps to start trading.
        </div>
      );
    } else {
      title = "Trading disabled";
      tooltipContent = <span>Trading is not available for this account.</span>;
    }
  }

  if (sellStatus === SellStatus.TOO_MANY_VAULTS) {
    title = "Trading disabled";
    if (isIra) {
      tooltipContent = (
        <span>
          IRA accounts are limited to a single active vault. To enable trading please close any
          additional vaults.{" "}
          <Link
            className="font-med"
            to="https://help.unchained.com/is-there-a-way-to-hide-or-delete-old-vaults-or-keys"
          >
            Learn more
          </Link>
        </span>
      );
    }
  }

  if (sellStatus === SellStatus.NO_SELL_POWER) {
    if (isIra) {
      title = offlineStatus?.title;
      tooltipContent = offlineStatus?.description;
    } else {
      title = "Account has no bitcoin available to sell";
      tooltipContent = (
        <span>
          By default, Unchained allows users to sell only as much bitcoin as they've purchased
          through our platform.{" "}
          <Link
            className="font-med"
            to="https://unchainedcapital.formstack.com/forms/sell_order_request"
          >
            Request increase to sell limit
          </Link>
        </span>
      );
    }
  }

  if (sellStatus === SellStatus.NO_BANKS) {
    title = "No bank accounts";
    tooltipContent = (
      <span>
        There are no bank accounts available to receive funds.{" "}
        <Link to="/profile/bank-accounts" onClick={AppModalManager.close} className="font-med">
          Add a bank account
        </Link>
      </span>
    );
  }

  if (sellStatus === SellStatus.PENDING_VAULT_TX) {
    title = "Pending vault transaction";
    tooltipContent = (
      <span>
        You can only have one vault transaction in progress at a time. To sell bitcoin, you must
        first complete or cancel any existing vault transaction.
      </span>
    );
  }

  if (!title) return null;
  return (
    <div className="-ml-2 font-med">
      {title}{" "}
      <Icons.HelpCircle
        className="ml-1 inline text-gray-500"
        size="xs"
        tooltip={{ content: tooltipContent }}
      />
    </div>
  );
}

export function SellFlowOrgSelectModal() {
  const { accountId: orgUuidInPath } = useParams();
  const workspaceData = useWorkspaceData();
  const { orgUuid } = useSellBitcoinStore();
  const queryClient = useQueryClient();
  const isIra = workspaceData.data?.orgs.find(org => org.id === orgUuid)?.accountType === "ira";
  const orgQuery = useGetOrg(orgUuid, { enabled: !!orgUuid });
  const dispatch = useSellBitcoinDispatch();
  const tradingStatusQuery = useTradingStatus(!!orgUuid && orgUuid !== "all" ? orgUuid : undefined);
  const sellStatus = useTradingSellInfo(orgUuid, tradingStatusQuery.data?.isSellBitcoinEnabled);
  const sellInProgress = tradingStatusQuery.data?.isSaleBeingAuthored;
  useGetAccount();

  const { replaceAccountCurrentOrg } = useActions({
    replaceAccountCurrentOrgAction,
  });

  const onOrgSelect = async (passedOrgUuid: string) => {
    const tradingStatus = await TradingAPI.GetTradingStatusV2(passedOrgUuid);
    queryClient.invalidateQueries(tradesKeys.tradingStatus(passedOrgUuid));
    queryClient.setQueryData(tradesKeys.tradingStatus(passedOrgUuid), tradingStatus);
    dispatch(setOrgUuid(passedOrgUuid, isIra));
  };

  const onContinue = () => {
    if (orgUuid) {
      replaceAccountCurrentOrg(orgQuery.data);

      AppModalManager.open(() => <SellBitcoinStepperWizard />);
    }
  };

  useEffect(() => {
    if (orgUuidInPath && !!dispatch && !!workspaceData.data?.orgs?.length) {
      const isIra =
        workspaceData.data?.orgs.find(org => org.id === orgUuidInPath)?.accountType === "ira";
      dispatch(setOrgUuid(orgUuidInPath, isIra));
    }
  }, [orgUuidInPath, dispatch, workspaceData.data?.orgs]);

  const isLoading = orgQuery.isLoading || sellStatus.isLoading || tradingStatusQuery.isLoading;

  return (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <div className="text-d-xs font-semi">Select account</div>
        <div>
          <p className="mb-4">Which account are you selling bitcoin from?</p>
        </div>
      </ModalHeader>
      <ModalContent className="min-h-[110px] min-w-[400px]">
        <>
          <OrgSelect
            onOrgSelect={onOrgSelect}
            orgUuid={orgUuid}
            error={
              !isLoading &&
              (!tradingStatusQuery?.data?.isSellBitcoinEnabled ||
                sellStatus.data?.data.status !== SellStatus.ONLINE)
            }
            isLoading={isLoading}
            helpText={
              sellInProgress ? (
                <span className="-ml-2 font-med">Sale in progress</span>
              ) : (
                <HelpText
                  sellStatus={sellStatus.data?.data.status}
                  offlineStatus={sellStatus.data?.data.offlineStatus}
                  isSellingFeatureAvailable={tradingStatusQuery?.data?.isSellBitcoinEnabled}
                  onboardingStatus={tradingStatusQuery?.data?.onboardingStatus}
                  isIra={isIra}
                  orgUuid={orgUuid}
                />
              )
            }
          />
        </>
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: "Continue",
            onClick: onContinue,
            disabled:
              !orgUuid ||
              orgQuery.isLoading ||
              sellStatus.data?.data.status !== SellStatus.ONLINE ||
              sellStatus.isLoading,
          },
        ]}
      />
    </Modal>
  );
}
