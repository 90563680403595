export const SET_SELL_AMOUNT = "SET_SELL_AMOUNT";
export const SET_SELL_BITCOIN_WEB_SOCKET_STATUS = "SET_SELL_BITCOIN_WEB_SOCKET_STATUS";
export const SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS = "SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS";
export const SET_SELL_BITCOIN_SOURCE = "SET_SELL_BITCOIN_SOURCE";
export const SET_SELECTED_BANK_ACCOUNT = "SET_SELECTED_BANK_ACCOUNT";
export const SET_SELECTED_KEYS = "SET_SELECTED_KEYS";
export const SET_IS_SHOW_CANCEL_MODAL = "SET_IS_SHOW_CANCEL_MODAL";
export const SET_IS_SELL_BITCOIN_AVAILABLE = "SET_IS_SELL_BITCOIN_AVAILABLE";
export const SET_SELL_INFO = "SET_SELL_INFO";
export const SET_SELL_IN_PROGRESS_DATA = "SET_SELL_IN_PROGRESS_DATA";
export const SET_CURRENT_SALE_UUID = "SET_CURRENT_SALE_UUID";
export const DELETE_SELL_BITCOIN_IN_PROGRESS = "DELETE_SELL_BITCOIN_IN_PROGRESS";
export const SET_STREAMING_QUOTE_SELL_RESPONSE = "SET_STREAMING_QUOTE_SELL_RESPONSE";
export const SET_SELL_BITCOIN_SUCCESS = "SET_SELL_BITCOIN_SUCCESS";
export const SET_SELL_BITCOIN_DENIED = "SET_SELL_BITCOIN_DENIED";
export const SET_SAVE_IN_PROGRESS_AND_CLOSE = "SET_SAVE_IN_PROGRESS_AND_CLOSE";
export const SET_CLOSE_CONFIRMED_SALE = "SET_CLOSE_CONFIRMED_SALE";
export const SET_RECEIVING_ACCOUNT_TYPE = "SET_RECEIVING_ACCOUNT_TYPE";
export const SET_ORG_UUID = "SET_ORG_UUID";
