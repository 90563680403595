import React, { useContext, useState } from "react";

import { CircularProgress } from "@mui/material";
import {
  Button,
  ButtonProps,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  WizardStepperContext,
} from "@unchained/component-library";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { getAccountSpendingDataAction } from "Actions/transactionActions/spendingActions";
import { ApprovalForm, QuorumCountDisplay } from "Components/Transactions/Spending/ApprovalStep";
import { Fee } from "Contexts/BuyBitcoin/types";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { ReceivingAccountType, SellKey, SellSource } from "Contexts/SellBitcoin/types";
import { SpendingApproval } from "Reducers/transactionReducers/spendingReducer";
import { useCurrentUser } from "Redux/selectors/hooks";
import { getCurrentOrg, spendingOperationSelector } from "Redux/selectors/spendingSelectors";
import { numMembersWhoCanApprove } from "Routes/manage/utils";
import { BtcTxRequestApi } from "Shared/api/btcTxRequestApi";
import { useSaleTx } from "Shared/api/hooks/trading";
import { TransactionRequestAllowedActionsEnum } from "Specs/v1/getTransactionRequests/200";
import { useActions } from "Utils/hooks/useActions";
import { formatCurrency } from "Utils/strings";
import { useErrorToast } from "Utils/toasts";

import {
  calculateAmountUSDToBeSentToClient,
  calculateFeeAmountInUSD,
  calculateSaleAmountInUSD,
} from "../../helpers";
import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { LoadableButton } from "../components/LoadableButton";
import { SaveProgressAndCloseButton } from "../components/SaveProgressAndCloseButton";
import { SellHeader } from "../components/SellHeader";
import { SigningKeyCard } from "../components/SigningKeyCard";
import { SummaryTable } from "./ReviewTransactionStep";

type ApproveTransactionStepProps = {
  onContinue?: () => void;
  onClose: () => void;
};
export const ApproveTransactionStep = React.memo(
  ({ onContinue, onClose }: ApproveTransactionStepProps) => {
    const {
      saleAmountBTC,
      selectedSource,
      bankName,
      bankLastFourDigits,
      transactionUuid,
      transactionFeeSatsVByte,
      feeRates,
      bitcoinPrice,
      selectedKeys,
      cashBalanceUsd,
      receivingAccountType,
    } = useSellBitcoinStore();

    const saleAmountUSD = calculateSaleAmountInUSD(bitcoinPrice, saleAmountBTC);
    const feeAmountUSD = calculateFeeAmountInUSD(saleAmountUSD, feeRates);
    const amountUSDToBeSentToClient = calculateAmountUSDToBeSentToClient(
      saleAmountUSD,
      feeAmountUSD
    );

    const { goToNext } = useContext(WizardStepperContext);

    const [isShowTxDetailsModal, setIsShowTxDetailsModal] = useState(false);

    const currentOrg = useSelector(getCurrentOrg);
    const isIraOrg = currentOrg.account_type === "ira";
    const { operation } = useSelector(spendingOperationSelector);
    const currentUser = useCurrentUser();

    const quorumSize = currentOrg.quorum_config?.min_approvals || 0;
    const txRequest = operation?.btc_transaction_requests[0];

    const availableApprovers = numMembersWhoCanApprove(currentOrg);
    const approvals = (txRequest?.approvals || [])?.filter(app => app.approver);
    const currentUserApprovalIndex = approvals?.findIndex(
      approval => approval?.approver?.uuid === currentUser.uuid
    );

    const { isTransactionLoading, isTransactionFetched } = useSaleTx(
      selectedSource?.id,
      transactionUuid
    );
    const isTransactionApproved = approvals?.length === quorumSize;

    const stepSubTitle = `Your organization requires at least ${quorumSize} approvals to confirm a transaction; please review and approve below. When finished, save and close or continue to signing step.`;

    const openTxDetails = () => {
      setIsShowTxDetailsModal(true);
    };
    const hideModal = () => setIsShowTxDetailsModal(false);

    const showErrorToast = useErrorToast();
    const { getAccountSpendingData } = useActions({ getAccountSpendingDataAction });

    const onRequest = {
      onSuccess: () => {
        getAccountSpendingData(
          selectedSource?.type,
          selectedSource?.id,
          "transaction",
          transactionUuid
        );
      },
      onError: error => {
        showErrorToast(error);
      },
    };
    const approveRequest = useMutation(() => BtcTxRequestApi.Approve(txRequest.uuid), onRequest);
    const unapproveRequest = useMutation(
      () => BtcTxRequestApi.Unapprove(txRequest.uuid),
      onRequest
    );

    const approvalFormActions = React.useMemo(() => {
      const canApprove = txRequest?.allowed_actions?.includes(
        "approve_btc_transaction" as unknown as TransactionRequestAllowedActionsEnum
      );
      const currentUserApproved = currentUserApprovalIndex !== -1;
      // Used as a proxy for who created the transaction
      const firstApprovalByCreatedAt = approvals.sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )[0];
      const currentUserWasFirstToApprove =
        firstApprovalByCreatedAt?.approver?.uuid === currentUser.uuid;

      const actions: ButtonProps[] = [];
      if (approvals.length === quorumSize || !canApprove || currentUserWasFirstToApprove) {
        return [];
      } else if (currentUserApproved) {
        actions.push({
          color: "destructive",
          children: "Revoke approval",
          onClick: () => unapproveRequest.mutate(),
          className: "mt-6 !h-9",
        });
      } else {
        actions.push({
          color: "primary",
          children: "Approve",
          onClick: () => approveRequest.mutate(),
          className: "mt-6 !bg-primary-600 hover:!bg-white !h-9",
        });
      }

      return actions;
    }, [
      approveRequest,
      unapproveRequest,
      quorumSize,
      currentUser.uuid,
      approvals,
      txRequest?.allowed_actions,
      currentUserApprovalIndex,
    ]);

    const onContinueSuccess = () => {
      onContinue();
      goToNext();
    };

    const handleNext = () => {
      onContinueSuccess();
    };

    return isTransactionFetched && !isTransactionLoading ? (
      <CancelModalWrapper closeStepper={onClose}>
        <div className="mb-4 mt-8  max-h-screen w-[50vw] max-w-[700px]">
          {isShowTxDetailsModal ? (
            <TransactionDetailsModal
              orgUuid={currentOrg.uuid}
              onDismiss={hideModal}
              selectedKeys={selectedKeys}
              saleAmountBTC={saleAmountBTC}
              selectedSource={selectedSource}
              feeAmountUSD={feeAmountUSD}
              amountUSDToBeSentToClient={amountUSDToBeSentToClient}
              bankName={bankName}
              bankLastFourDigits={bankLastFourDigits}
              transactionFeeSatsVByte={transactionFeeSatsVByte}
              feeRates={feeRates}
              receivingAccountType={receivingAccountType}
              cashBalanceUsd={cashBalanceUsd}
              isIraOrg={isIraOrg}
            />
          ) : null}
          <SellHeader className="mb-8" title="Approve transaction" subTitle={stepSubTitle} />
          <div className="mb-8 flex flex-row">
            <div className="flex w-[47%] flex-col">
              <h3 className="mb-4 text-sm font-bold text-gray-700">Transaction overview</h3>
              <div className="flex flex-1 flex-col rounded-lg border border-gray-200 bg-gray-50 p-4">
                <TxOverviewItem
                  title="Selling vault"
                  value={selectedSource?.name}
                  postFix={`ID: ${selectedSource?.id}`}
                />
                <TxOverviewItem title="Bitcoin to be sold" value={saleAmountBTC} postFix={"BTC"} />

                <TxOverviewItem
                  title="Estimated proceeds"
                  value={`$${formatCurrency(amountUSDToBeSentToClient)}`}
                />
                <Button
                  className="[&:not(:hover):!text-primary-600]  !mt-auto !justify-start !p-0 text-sm font-med"
                  variant="text"
                  onClick={openTxDetails}
                >
                  View full transaction details
                </Button>
              </div>
            </div>
            <div className="w-[6%]"></div>
            <div className="w-[47%]">
              <h3 className="mb-4 text-sm font-bold text-gray-700">Approvals gathered</h3>
              <QuorumCountDisplay approvalsCount={approvals?.length} quorumSize={quorumSize} />
              <ApprovalForm
                availableApprovers={availableApprovers}
                quorumSize={quorumSize}
                approvals={approvals as SpendingApproval[]}
                currentUserApprovalIndex={currentUserApprovalIndex}
                actions={approvalFormActions}
                className="mt-6"
              />
            </div>
          </div>
          <LoadableButton
            fullWidth={true}
            disabled={!isTransactionApproved}
            color="primary"
            onClick={handleNext}
            isLoading={false}
          >
            Continue
          </LoadableButton>
          <SaveProgressAndCloseButton onClose={onClose} />
        </div>
      </CancelModalWrapper>
    ) : (
      <CancelModalWrapper closeStepper={onClose}>
        <CircularProgress color="primary" size={53} />
      </CancelModalWrapper>
    );
  }
);

type TxOverviewItemProps = {
  title: string;
  value: string;
  postFix?: string;
};
const TxOverviewItem = ({ title, value, postFix }: TxOverviewItemProps) => {
  const valueStyles = postFix ? "truncate max-w-[55%]" : "";
  return (
    <div className="mb-4">
      <p className="text-sm font-reg">{title}</p>
      <div className="flex flex-row items-baseline justify-start">
        <p className={`text-lg font-semi text-gray-800 ${valueStyles}`}>{value}</p>
        {postFix ? <span className="ml-2 text-sm font-med text-gray-600">{postFix}</span> : null}
      </div>
    </div>
  );
};

type TransactionDetailsModalProps = {
  onDismiss: () => void;
  selectedKeys: SellKey[];
  saleAmountBTC: string;
  selectedSource: SellSource;
  feeAmountUSD: string;
  amountUSDToBeSentToClient: string;
  bankName: string;
  bankLastFourDigits: string;
  transactionFeeSatsVByte: string;
  feeRates: Fee[];
  receivingAccountType: ReceivingAccountType;
  cashBalanceUsd?: string;
  isIraOrg: boolean;
  orgUuid: string;
};

const TransactionDetailsModal = ({
  onDismiss,
  selectedKeys,
  saleAmountBTC,
  selectedSource,
  feeAmountUSD,
  amountUSDToBeSentToClient,
  bankName,
  bankLastFourDigits,
  transactionFeeSatsVByte,
  feeRates,
  receivingAccountType,
  cashBalanceUsd,
  isIraOrg,
  orgUuid,
}: TransactionDetailsModalProps) => {
  const footerButtons: ButtonProps[] = [
    {
      onClick: onDismiss,
      children: "Close",
      color: "secondary",
    },
  ];

  const signingKeyCardsDisplay = selectedKeys.map(keyData => (
    <SigningKeyCard
      key={keyData.name}
      className="mr-4"
      title={keyData.name}
      isUnchained={keyData.isUnchained}
      isDelegatedKey={keyData.isDelegatedKey}
      logoSlug={keyData.logoSlug}
    />
  ));
  return (
    <Modal onDismiss={onDismiss} className="max-h-screen w-[50vw] max-w-[700px]">
      <ModalHeader>
        <ModalTitle>Transaction details</ModalTitle>
      </ModalHeader>

      <p className="mt-6 text-sm font-reg">Signing keys</p>
      <div className="mb-5 mt-1 flex flex-col md:flex-row">{signingKeyCardsDisplay}</div>

      <div className=" w-full" data-testid="txDetailsSummaryTable">
        <SummaryTable
          orgUuid={orgUuid}
          saleAmount={saleAmountBTC}
          vaultName={selectedSource?.name}
          feeAmountInUsd={feeAmountUSD}
          vaultId={selectedSource?.id}
          amountUSDToBeSentToClient={amountUSDToBeSentToClient}
          bankAccountName={bankName}
          bankAccountLastFourDigits={bankLastFourDigits}
          transactionFeeSatsVByte={transactionFeeSatsVByte}
          feeRates={feeRates}
          receivingAccountType={receivingAccountType}
          cashBalanceUsd={cashBalanceUsd}
          isIraOrg={isIraOrg}
        />
      </div>
      <ModalFooter actions={footerButtons}></ModalFooter>
    </Modal>
  );
};
