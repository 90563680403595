import { useGetOrg } from "Shared/api/hooks/orgs";
import { useCurrentOrgUuid } from "Utils/hooks/useCurrentOrg";

import { useCurrentOrgDeprecated } from "./hooks";

type OrgFeature =
  | "cash_balance"
  | "coldcard"
  | "consumer_lending"
  | "consumer_lending_ca"
  | "custom_psbt_signing"
  | "custom_xpub_upload"
  | "hermit"
  | "lead_bank_originations"
  | "locked_for_payment"
  | "pockets"
  | "qa_sign"
  | "sell_bitcoin"
  | "unchained_signing_fee"
  | "segwit_wallets"
  | "keyless_connection_vault_sharing_sender";

// Return undefined if the currentOrg's feature set has not loaded yet
export function useOrgFeatureOn(name: OrgFeature): boolean | undefined {
  const orgUuid = useCurrentOrgUuid();
  const orgQuery = useGetOrg(orgUuid, { enabled: !!orgUuid });

  if (orgQuery.data?.all_features) {
    return Boolean(orgQuery.data.all_features[name]);
  } else {
    return undefined;
  }
}
