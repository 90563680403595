import { useContext, useEffect } from "react";

import { CircularProgress } from "@mui/material";
import { Button, Icons, useToast, WizardStepperContext } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { orgDisplayInfo } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/accountHelpers";
import { useNavigate } from "Components/Link";
import { TradingServiceDisclosure } from "Components/TradingDesk/components/TradingServiceDisclosure";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { ReceivingAccountType } from "Contexts/SellBitcoin/types";
import { getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import { SellFlowOrgSelectModal } from "Routes/accounts/all/(components)/SellFlowOrgSelectModal";
import {
  useCreateInProgressSaleMutation,
  useUpdateSellBitcoinSaleDetails,
} from "Shared/api/hooks/trading";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { AppModalManager } from "Shared/components/Modals";

import { sellBitcoinNetworkFailureToastDescription } from "../../helpers";
import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { SellBitcoinForm } from "../components/SellBitcoinForm";

type SellDetailsStepProps = {
  onCancel: () => void;
  onContinue: () => void;
};
export const SaleDetailsStep = ({ onCancel, onContinue }: SellDetailsStepProps) => {
  const { goToNext } = useContext(WizardStepperContext);
  const { currentSaleUuid } = useSellBitcoinStore();
  const orgUuid: string = useSelector(getCurrentOrgId);
  const createSaleInProgress = useCreateInProgressSaleMutation(orgUuid);

  const workspaceData = useWorkspaceData();
  const isUnifiedIA = workspaceData.data?.user?.canViewUnifiedIA;
  const org = workspaceData.data?.orgs.find(o => o.id === orgUuid);
  const { Avatar: OrgAvatar, color } = orgDisplayInfo({
    ...org,
    account_type: org.accountType,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentSaleUuid) {
      createSaleInProgress.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSaleUuid]);

  const handleUpdateSuccess = () => {
    onContinue();
    goToNext();
  };
  const { enqueueSimpleToast } = useToast();

  const handleUpdateError = () => {
    enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
  };
  const { mutate: updateSellBitcoinSaleDetails, isLoading: isSubmitLoading } =
    useUpdateSellBitcoinSaleDetails({
      orgUuid,
      currentSaleUuid,
      handleOnSuccess: handleUpdateSuccess,
      handleOnError: handleUpdateError,
    });
  const handleNext = (
    bankUuid: string,
    sellingVaultUuid: string,
    saleAmountBtc: string,
    receivingAccountType: ReceivingAccountType
  ) => {
    updateSellBitcoinSaleDetails({
      bankUuid,
      sellingVaultUuid,
      saleAmountBtc,
      receivingAccountType,
    });
  };

  return currentSaleUuid ? (
    <CancelModalWrapper closeStepper={onCancel}>
      <div className="max-h-screen">
        {isUnifiedIA && (
          <Button
            startIcon={<Icons.ArrowLeft />}
            className="text-primary-600"
            variant="text"
            onClick={() => {
              navigate(`/accounts/${orgUuid}`);
              AppModalManager.open(() => <SellFlowOrgSelectModal />);
            }}
          >
            Back
          </Button>
        )}
        <h2 className="mb-0.5 text-d-sm">Enter details</h2>
        <div className="mb-4 mt-2 flex items-center gap-2">
          <OrgAvatar title={org.title} className={`bg-[${color}]`} />
          <div className="flex flex-col">
            <p className="text-gray-600">{org.title}</p>
            <p className="text-xs text-gray-400">{org.subtitle}</p>
          </div>
        </div>
      </div>
      <SellBitcoinForm next={handleNext} onCancel={onCancel} isSubmitLoading={isSubmitLoading} />
      <TradingServiceDisclosure />
    </CancelModalWrapper>
  ) : (
    <div className="flex flex-col items-center justify-center">
      <p className="mb-8">Trading session initiating</p>
      <CircularProgress
        data-testid="sell-bitcoin-wizard-loading-spinner"
        color="primary"
        size={53}
      />
    </div>
  );
};
