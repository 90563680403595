import { FC } from "react";

import l from "@loadable/component";
import { Loader } from "@unchained/component-library";
import { Navigate } from "react-router-dom";

import { withLoadingContext } from "Contexts/LoadingContext";
import { NavigateBack } from "Routes/(utils)/Authorize/NavigateWithToast";
import { sendToUIA } from "Routes/(utils)/routing/PrependWithOrgId";
import { SwapRoute } from "Routes/(utils)/routing/Remap";
import { IRAConsentDash } from "Routes/onboard/[uuid]/(ira)/steps/Checkout/IRAConsent";

import { Route } from "./routes/(utils)/routing/Route";
import { RouteGroup } from "./routes/(utils)/routing/RouteGroup";
import { generateRouter } from "./routes/(utils)/routing/generateRouter";

// Shorthand to make imports more readable one-liners
const o = { fallback: <Loader className="h-screen" /> };

// An object that maps component names to their lazy-loaded components
// All components rendered at routes should be imported using the below pattern.
const NameComponentMap = {
  Account: l(() => import("Routes/accounts/[accountId]"), o),
  AccountItem: l(() => import("Routes/accounts/[accountId]/[tabName]/[itemId]"), o),
  AccountKeyConfig: l(() => import("Routes/account-keys/[uuid]/config"), o),
  AccountKeyNewWizard: l(() => import("Components/AccountKeys/AccountKeyNewWizard"), o),
  AccountKeyShowSigner: l(() => import("Components/account_keys/AccountKeyShowSigner"), o),
  AccountKeysList: l(() => import("Routes/account_keys"), o),
  AccountsAll: l(() => import("Routes/accounts/all"), o),
  AdminListIraOrgs: l(() => import("Components/Orgs/IraAdmin/AdminListIraOrgs"), o),
  AdminListTrustOrgs: l(() => import("Routes/admin/orgs/trusts/AdminListTrusts"), o),
  AdminShowIraOrg: l(() => import("Components/Orgs/IraAdmin/AdminShowIraOrg"), o),
  AdminShowTrust: l(() => import("Routes/admin/orgs/trusts/[uuid]/AdminShowTrust"), o),
  BitcoinWallet: l(() => import("Components/wallet/BitcoinWallet"), o),
  BtcTransactionRequestsList: l(
    () => import("Components/BtcTransactionRequests/BtcTransactionRequestsList"),
    o
  ),
  CashBalance: l(() => import("Components/PaymentMethods/CashBalance"), o),
  ChangeProposalList: l(() => import("Routes/change-proposals/OrgChangeProposals"), o),
  ChangeProposalGroup: withLoadingContext(
    l(() => import("Routes/change-proposals/[uuid]/ChangeProposalGroup"), o)
  ),
  CollateralSaleRequests: l(() => import("Components/loans/CollateralSaleRequests"), o),
  ConfirmEmailChange: l(
    () => import("Components/credentials/ConfirmEmailChange/ConfirmEmailChange"),
    o
  ),
  ConfirmPasswordReset: l(
    () => import("Components/Authentication/password_resets/ConfirmPasswordReset"),
    o
  ),
  ConnectionDetails: l(() => import("Routes/connections/ConnectionDetailsPage"), o),
  Connections: l(() => import("Routes/connections"), o),
  Dashboard: l(() => import("Components/Dashboard/Dashboard"), o),
  DelegatedCustodyClientList: l(() => import("Components/DelegatedCustody/OrgList"), o),
  DocumentError: l(() => import("Components/errors/DocumentError"), o),
  Documents: l(() => import("Routes/documents"), o),
  EnableTOTPPage: l(() => import("Routes/settings/(Security)/EnableTOTP"), o),
  Features: l(() => import("Components/features/Features"), o),
  InheritanceCheckout: l(() => import("Routes/inheritance"), o),
  InvoiceStepper: l(() => import("Routes/invoices/[uuid]/InvoiceStepper"), o),
  IRABeneficiaries: l(() => import("Routes/ira-beneficiaries"), o),
  ListMFAResets: l(() => import("Components/credentials/MFAResets/ListMFAResets"), o),
  LoanContractSigned: l(() => import("Components/loans/LoanContractSigned"), o),
  LoanNewContainer: l(() => import("Components/LoansView/NewFlow/LoanNewContainer"), o),
  LoanPaymentsDue: l(() => import("Components/loans/LoanPaymentsDue"), o),
  LoanShowAdmin: l(() => import("Components/loans/LoanShowAdmin/index"), o),
  LoanShowUser: l(() => import("Components/LoansView/Show/LoanShowUser"), o),
  LoanSignContract: l(() => import("Components/loans/LoanSignContract"), o),
  LoansListAdmin: l(() => import("Components/loans/LoansListAdmin"), o),
  LoansListArbiter: l(() => import("Components/loans/LoansListArbiter"), o),
  LoansListClient: l(() => import("Components/loans/LoansListClient"), o),
  Login: l(() => import("Components/Authentication/Login"), o),
  Logout: l(() => import("Components/Authentication/Logout"), o),
  MFAResetRecord: l(() => import("Components/credentials/MFAResets/MFAResetRecord"), o),
  OnboardBasicInfo: l(() => import("Routes/onboard/OnboardBasicInfo"), o),
  OnboardOrg: l(() => import("Routes/onboard/[uuid]/OnboardOrg"), o),
  OrgManage: l(() => import("Routes/manage"), o),
  OrgShowAdmin: l(() => import("Components/Orgs/Show/OrgShowAdmin/OrgShowAdmin"), o),
  OrgShowArbiter: l(() => import("Components/Orgs/Show/OrgShowArbiter"), o),
  OrgsList: l(() => import("Components/Orgs/List/OrgsList"), o),
  PaymentInstructions: l(() => import("Components/payments/PaymentInstructions"), o),
  PaymentMethods: l(() => import("Components/PaymentMethods/PaymentMethods"), o),
  PriceChart: l(() => import("Routes/price-chart"), o),
  ProductBundles: l(() => import("Routes/admin/bundles/ProductBundles"), o),
  Profile: l(() => import("Routes/profile"), o),
  RekeyShow: l(() => import("Components/rekeys/RekeyShow"), o),
  RekeyShowAdmin: l(() => import("Components/rekeys/RekeyShowAdmin"), o),
  RekeysList: l(() => import("Components/rekeys/RekeysList"), o),
  RequestPasswordReset: l(
    () => import("Components/Authentication/password_resets/RequestPasswordReset"),
    o
  ),
  Settings: l(() => import("Routes/settings/Settings"), o),
  ShowMFAResetAdmin: l(() => import("Components/credentials/MFAResets/ShowMFAResetAdmin"), o),
  SignatureUpgradeStepper: l(() => import("Routes/checkout/SignatureUpgradeStepper"), o),
  Signup: l(() => import("Components/Authentication/Signup"), o),
  SignupConfirm: l(() => import("Components/Authentication/SignupConfirm"), o),
  SpendingWizard: l(() => import("Components/Transactions/Spending/SpendingWizard"), o),
  SubscriptionManagement: l(() => import("Routes/subscriptions/SubscriptionManagement"), o),
  Support: l(() => import("Components/Support/Support"), o),
  TradingDeskAdmin: l(() => import("Components/TradingDesk/admin"), o),
  UsdActivity: l(() => import("Components/UsdActivity/UsdActivity"), o),
  UserShowAdmin: l(() => import("Components/users/UserShowAdmin/UserShowAdmin"), o),
  UsersList: l(() => import("Components/users/UsersList"), o),
  VaultNewContainer: l(() => import("Components/VaultsView/Create/VaultNewContainer"), o),
  VaultShowUser: l(() => import("Components/VaultsView/Show/VaultShowUser"), o),
  VaultsListAdmin: l(() => import("Components/vaults/VaultsListAdmin"), o),
  VaultsListArbiter: l(() => import("Components/vaults/VaultsListArbiter"), o),
  VaultsListUser: l(() => import("Routes/vaults"), o),
  VerificationsList: l(() => import("Components/verifications/VerificationsList"), o),
  UIAKeys: l(() => import("Routes/keys"), o),
  UIAKeyDetails: l(() => import("Routes/keys/[uuid]"), o),
  NotFound: l(() => import("Components/errors/NotFound"), o),
} as const;

// An object that maps component names to a
// an object with the component name and the component.
// This is to simplify the route configuration.
const Comps = Object.entries(NameComponentMap).reduce(
  (acc, [name, component]) => ({
    ...acc,
    [name]: { name, component },
  }),
  {}
) as Record<keyof typeof NameComponentMap, { name: string; component: FC }>;

// Shared bools for brevity/clarity
const requireUIA = true;
const requireBasicInfo = true;
const requireOrgRepayment = true;
const requirePostOnboardingOrg = true;
const requireIraConsent = true;
const requireAdmin = true;
const blockUIA = true;
const blockAdmin = true;
const requireOrgInUrl = true;
const requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg = true;

export const routes = new RouteGroup().children({
  "/login": new Route(Comps.Login),
  "/logout": new Route(Comps.Logout),
  "/sign_up": new Route(Comps.Signup),
  "/sign_ups/:signup_token": new RouteGroup().children({
    "/": new Route(Comps.Signup),
    "/confirm": new Route(Comps.SignupConfirm),
  }),
  "/credentials": new RouteGroup().children({
    "/password/reset": new RouteGroup().children({
      "/request": new Route(Comps.RequestPasswordReset),
      "/:token": new Route(Comps.ConfirmPasswordReset),
    }),
    "/email/change/:token": new Route(Comps.ConfirmEmailChange),
  }),

  "/accounts": new RouteGroup()
    .childAuthorizers({
      requireUIA,
      requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg,
    })
    .children({
      "/": Route.redirect("/accounts/all"),
      "/all": new Route(Comps.AccountsAll),
      "/:accountId": new RouteGroup()
        .childAuthorizers({ requireOrgInUrl, requireNotOrgState: ["pending_approval"] })
        .children({
          "/": new Route(Comps.Account),
          "/:tabName": new RouteGroup()
            .childAuthorizers({ requirePostOnboardingOrg, requireIraConsent })
            .children({
              "/": new Route(Comps.Account),
              "/:itemId": new Route(Comps.AccountItem).authorizers({
                requireOrgRepayment,
                // Comps.Account blocks for payment internally.
                // If you try to "get by it" and view a sub-item, we send you back there
                requireOrgRepaymentReplacement: "/accounts/:accountId",
              }),
            }),

          "/vaults/new": new Route(Comps.VaultNewContainer).authorizers({
            requireOrgRepayment,
            requireIraConsent,
            requirePostOnboardingOrg,
          }),
          "/vaults/:uuid/:operation_type/:operation_uuid": new Route(
            Comps.SpendingWizard
          ).authorizers({
            requireOrgRepayment,
            requireIraConsent,
            requirePostOnboardingOrg,
          }),

          "/loans/new": new Route(Comps.LoanNewContainer).authorizers({
            requireOneOfOrgAccountTypes: ["business", "trust"],
            requireOrgRepayment,
            requirePostOnboardingOrg,
          }),
          "/loans/:uuid/:operation_type/:operation_uuid": new Route(
            Comps.SpendingWizard
          ).authorizers({
            requirePostOnboardingOrg,
            requireIraConsent,
            requireOrgRepayment,
          }),
        }),
    }),

  "/profile": new RouteGroup()
    .childAuthorizers({ requireUIA, requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg })
    .children({
      "/": Route.redirect("/profile/contact-information"),
      "/contact-info": Route.redirect("/profile/contact-information"),
      "/:tabName": new Route(Comps.Profile),
    }),

  "/keys": new RouteGroup()
    .childAuthorizers({ requireUIA, requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg })
    .children({
      "/": new Route(Comps.UIAKeys),
      "/new": new Route(Comps.AccountKeyNewWizard),
      "/:uuid": new RouteGroup().children({
        "/": new Route(Comps.UIAKeyDetails),
        "/rekey": new Route(Comps.RekeyShow),
      }),
    }),

  "/admin": new RouteGroup().childAuthorizers({ requireAdmin }).children({
    "/users": new RouteGroup().children({
      "/": new Route(Comps.UsersList),
      "/:uuid": new Route(Comps.UserShowAdmin),
    }),
    "/orgs": new RouteGroup().children({
      "/": new Route(Comps.OrgsList)
        .authorizers({
          requireOneOfOrgTypes: ["unchained", "arbiter"],
        })
        .DEPRECATED_removeInheritedAuthorizers(["requireAdmin"]),
      "/trusts": new RouteGroup().children({
        "/": new Route(Comps.AdminListTrustOrgs),
        "/:uuid": new Route(Comps.AdminShowTrust),
      }),
      "/ira": new RouteGroup().children({
        "/": new Route(Comps.AdminListIraOrgs),
        "/:orgUuid": new Route(Comps.AdminShowIraOrg),
      }),
      "/:uuid": new Route(Comps.OrgShowAdmin),
      "/delegated-custody/clients": new Route(Comps.DelegatedCustodyClientList)
        .authorizers({
          requireOneOfOrgTypes: ["unchained", "delegate"],
        })
        .DEPRECATED_removeInheritedAuthorizers(["requireAdmin"]),
    }),
    "/bundles": new Route(Comps.ProductBundles),
    "/loans": new RouteGroup().children({
      "/": new Route(Comps.LoansListAdmin),
      "/payments/due": new Route(Comps.LoanPaymentsDue)
        .authorizers({ requireOneOfOrgTypes: ["unchained", "arbiter"] })
        .DEPRECATED_removeInheritedAuthorizers(["requireAdmin"]),

      "/collateral-sale-requests": new Route(Comps.CollateralSaleRequests)
        .authorizers({
          requireOneOfOrgTypes: ["arbiter", "unchained"],
          requireOrgRepayment,
        })
        .DEPRECATED_removeInheritedAuthorizers(["requireAdmin"]),
      "/:uuid": new Route(Comps.LoanShowAdmin),
    }),
    "/vaults": new RouteGroup().children({
      "/": new Route(Comps.VaultsListAdmin),
      "/:uuid": new Route(Comps.VaultShowUser),
    }),
    "/mfa/resets": new RouteGroup().children({
      "/": new Route(Comps.ListMFAResets),
      "/:uuid_or_token": new Route(Comps.ShowMFAResetAdmin),
    }),
    "/account_keys/:uuid": new RouteGroup().children({
      "/rekey": new Route(Comps.RekeyShowAdmin),
      "/config": new Route(Comps.AccountKeyConfig),
    }),
    "/rekeys": new Route(Comps.RekeysList),
    "/features": new Route(Comps.Features),
    "/verifications": new Route(Comps.VerificationsList),
    "/trading": new Route(Comps.TradingDeskAdmin).authorizers({
      requireOrgAllowedActions: ["view_trading_info"],
      requireOrgAllowedActionsReplacement: () => <NavigateBack />,
      requireAdminReplacement: () => <NavigateBack />,
    }),
    "/usd-activity": new Route(Comps.UsdActivity).authorizers({
      requireOrgAllowedActions: ["view_usd_activity"],
    }),
    "/btc-transaction-requests": new Route(Comps.BtcTransactionRequestsList)
      .authorizers({
        requireOneOfOrgTypes: ["unchained", "arbiter"],
      })
      .DEPRECATED_removeInheritedAuthorizers(["requireAdmin"]),
    "/wallet": new Route(Comps.BitcoinWallet),
  }),

  "/arbiter": new RouteGroup()
    .childAuthorizers({ requireOneOfOrgTypes: ["unchained", "arbiter"] })
    .children({
      "/orgs": new RouteGroup().children({
        "/": new Route(Comps.OrgsList),
        "/:uuid": new Route(Comps.OrgShowArbiter),
      }),
      "/loans": new RouteGroup().children({
        "/": new Route(Comps.LoansListArbiter),
        "/:uuid": new Route(Comps.LoanShowUser),
      }),
      "/vaults": new RouteGroup().children({
        "/": new Route(Comps.VaultsListArbiter),
        "/:uuid": new Route(Comps.VaultShowUser),
      }),
    }),

  // Client orgs
  "/orgs": new RouteGroup()
    .childAuthorizers({ requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg })
    .children({
      // An old-route redirect that needed to be moved above
      "/": Route.redirect("/admin/orgs"),
      "/:uuid/change-proposals": new RouteGroup().children({
        "/": new Route(Comps.ChangeProposalList),
        "/:groupUuid": new Route(Comps.ChangeProposalGroup),
      }),
    }),

  // Client loans
  "/loans": new RouteGroup()
    .childAuthorizers({ requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg, ...sendToUIA })
    .children({
      "/": new Route(Comps.LoansListClient).authorizers({
        requireOneOfOrgAccountTypes: ["individual", "business", "trust"],
      }),
      "/new": new Route(Comps.LoanNewContainer).authorizers({
        requireOneOfOrgAccountTypes: ["individual", "business", "trust"],
      }),
      "/:uuid": new RouteGroup().children({
        "/": new Route(Comps.LoanShowUser).authorizers({
          requireOrgRepayment,
        }),
        "/:operation_type/:operation_uuid": new Route(
          Comps.SpendingWizard
        ).DEPRECATED_removeInheritedAuthorizers(["blockUIA"]),
        "/contract": new RouteGroup().children({
          "/sign": new Route(Comps.LoanSignContract),
          "/signed": new Route(Comps.LoanContractSigned),
        }),
      }),
    }),

  // Client vaults
  "/vaults": new RouteGroup()
    .childAuthorizers({
      requireOrgRepayment,
      requireIraConsent,
      ...sendToUIA,
    })
    .children({
      "/": new Route(Comps.VaultsListUser),
      "/new": new Route(Comps.VaultNewContainer),
      "/:uuid": new RouteGroup().children({
        "/": new Route(Comps.VaultShowUser),
        "/:operation_type/:operation_uuid": new Route(
          Comps.SpendingWizard
        ).DEPRECATED_removeInheritedAuthorizers(["blockUIA"]),
      }),
    }),

  // Client MFA
  "/mfa": new RouteGroup().children({
    "/enable": new Route(Comps.EnableTOTPPage),
    "/resets": new Route(Comps.MFAResetRecord),
  }),

  // Client account keys
  "/account_keys": new RouteGroup()
    .childAuthorizers({
      requireOrgRepayment,
      blockUIA,
      blockUIAReplacement: () => <SwapRoute to="/keys" from="/account_keys" />,
    })
    .children({
      "/": new Route(Comps.AccountKeysList),
      "/new": new Route(Comps.AccountKeyNewWizard),

      "/:uuid": new RouteGroup().children({
        "/": new Route(Comps.AccountKeyShowSigner),
        "/rekey": new Route(Comps.RekeyShow),
      }),
    }),

  // Client connections
  "/connections": new RouteGroup().childAuthorizers({ ...sendToUIA }).children({
    "/": new Route(Comps.Connections),
    "/connection/:connectionUuid": new Route(Comps.ConnectionDetails).authorizers({
      blockUIAReplacement: ({ orgId, params }) => (
        <Navigate to={`/accounts/${orgId}/connections/${params.connectionUuid}`} />
      ),
    }),
  }),

  "/onboard": new RouteGroup().children({
    "/": new Route(Comps.OnboardBasicInfo),
    "/:uuid": new Route(Comps.OnboardOrg).authorizers({ requireBasicInfo }),
  }),

  "/invoices/:uuid": new Route(Comps.InvoiceStepper),
  "/subscriptions": new Route(Comps.SubscriptionManagement),
  "/checkout/signature": new Route(Comps.SignatureUpgradeStepper),
  "/new-signature": Route.redirect("/checkout/signature?from=/onboard"),

  "/home": new Route(Comps.Dashboard).authorizers({
    blockUIA,
    requireOrgRepayment,
    requireBasicOnboardingIfNotAMemberOfAnotherLiveOrg,
    blockAdmin,
    requireIraConsent,
    requireIraConsentReplacement: ({ orgId }) => <IRAConsentDash uuid={orgId} />,
  }),

  "/cash-balance": new Route(Comps.CashBalance),

  "/settings": new Route(Comps.Settings).authorizers({
    blockUIA,
    blockUIAReplacement: ({ queryParams }) => {
      // User being sent from mobile to delete their account.
      // Pass them on immediately
      if (queryParams.tab === "my-accounts" && !!queryParams.uuid) {
        return `/accounts/all?leaveId=${queryParams.uuid}`;
      }

      return [`/profile`, queryParams.tab].filter(Boolean).join("/");
    },
  }),

  "/documents": new Route(Comps.Documents),
  "/manage": new Route(Comps.OrgManage).authorizers({ blockUIA }),
  "/payment-methods": new Route(Comps.PaymentMethods),
  "/ira-beneficiaries": new Route(Comps.IRABeneficiaries),
  "/payment_instructions": new Route(Comps.PaymentInstructions),
  "/document/error": new Route(Comps.DocumentError),
  "/support": new Route(Comps.Support),
  "/inheritance": new Route(Comps.InheritanceCheckout),
  "/price-chart": new Route(Comps.PriceChart).authorizers({ requireUIA }),
  "/*": new Route(Comps.NotFound),

  // Redirects from old routes to new routes
  // It'd be nice to delete these one at a time as we
  // find-and-replace the old routes.

  // Admin
  "/mfa/resets/admin": Route.redirect("/admin/mfa/resets"),
  "/mfa/resets/:uuid_or_token/admin": Route.redirect("/admin/mfa/resets/:uuid_or_token"),
  "/account_keys/:uuid/config": Route.redirect("/admin/account_keys/:uuid/config"),
  "/account_keys/:uuid/rekey/admin": Route.redirect("/admin/account_keys/:uuid/rekey"),
  "/btc_transaction_requests": Route.redirect("/admin/btc-transaction-requests"),
  "/features": Route.redirect("/admin/features"),
  "/loans/:uuid/admin": Route.redirect("/admin/loans/:uuid"),
  "/loans/admin": Route.redirect("/admin/loans"),
  "/loans/collateral-sale-requests": Route.redirect("/admin/loans/collateral-sale-requests"),
  "/loans/payments/due": Route.redirect("/admin/loans/payments/due"),
  "/orgs/:uuid/admin": Route.redirect("/admin/orgs/:uuid"),
  "/orgs/delegated-custody/clients": Route.redirect("/admin/orgs/delegated-custody/clients"),
  "/orgs/ira/:orgUuid/admin": Route.redirect("/admin/orgs/ira/:orgUuid"),
  "/orgs/ira/admin": Route.redirect("/admin/orgs/ira"),
  "/rekeys": Route.redirect("/admin/rekeys"),
  "/trading": Route.redirect("/admin/trading"),
  "/usd_activity": Route.redirect("/admin/usd-activity"),
  "/users": Route.redirect("/admin/users"),
  "/users/:uuid": Route.redirect("/admin/users/:uuid"),
  "/vaults/:uuid/admin": Route.redirect("/admin/vaults/:uuid"),
  "/vaults/admin": Route.redirect("/admin/vaults"),
  "/verifications": Route.redirect("/admin/verifications"),
  "/wallet": Route.redirect("/admin/wallet"),
  "/wallet/BTC": Route.redirect("/admin/wallet"),

  // Arbiter
  "/orgs/:uuid": Route.redirect("/arbiter/orgs/:uuid"),

  // Misc
  "/orgs/ira/:orgUuid": Route.redirect("/onboard/:orgUuid"),

  // UIA cleanup:
  "/accounts/:accountId/connections/connection/:connectionUuid": Route.redirect(
    "/accounts/:accountId/connections/:connectionUuid"
  ),
});

export const router = generateRouter(routes);
