import { useMemo, useState } from "react";

import {
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { replaceAccountCurrentOrgAction } from "Actions/accountActions/orgActions";
import { Link } from "Components/Link";
import OrgSelect from "Components/TradingDesk/components/OrgSelect";
import { BuyBitcoinModalBase } from "Components/TradingDesk/modals/BuyBitcoinBaseModal";
import { TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE } from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { useTradingStatus } from "Shared/api";
import { useGetOrg } from "Shared/api/hooks/orgs";
import { AppModalManager } from "Shared/components/Modals";
import { useActions } from "Utils/hooks";

export function BuyFlowOrgSelectModal() {
  const { accountId: orgUuidInPath } = useParams();
  const [orgUuid, setOrgUuid] = useState(orgUuidInPath || "");
  const orgQuery = useGetOrg(orgUuid, { enabled: !!orgUuid && orgUuid !== "all" });

  const tradingStatusQuery = useTradingStatus(!!orgUuid && orgUuid !== "all" ? orgUuid : undefined);
  const shouldShowContinueOnboarding = useMemo(() => {
    const onboardingStatus = tradingStatusQuery.data?.onboardingStatus;
    const isIra = orgQuery.data?.account_type === "ira";
    if (!onboardingStatus) return false;
    if (!onboardingStatus.hasVault) return true;
    if (onboardingStatus.isTierThreeProfile !== TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE)
      return true;
    if (
      !isIra &&
      onboardingStatus.isBankAccountOnFile !== TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE
    )
      return true;
    return false;
  }, [tradingStatusQuery.data, orgQuery.data]);

  const { replaceAccountCurrentOrg } = useActions({
    replaceAccountCurrentOrgAction,
  });

  const isTradingFeatureAvailable =
    orgUuid === "all" || tradingStatusQuery.data?.isBuyBitcoinEnabled;

  const onOrgSelect = (orgUuid: string) => {
    setOrgUuid(orgUuid);
  };

  const onContinue = () => {
    if (orgUuid) {
      replaceAccountCurrentOrg(orgQuery.data);

      AppModalManager.open(() => <BuyBitcoinModalBase defaultVault={undefined} />);
    }
  };

  return (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <div className="text-d-xs font-semi">Select account</div>
        <div>
          <p className="mb-4">Which account are you buying bitcoin to?</p>
        </div>
      </ModalHeader>
      <ModalContent className="min-h-[96px] min-w-[400px]">
        <>
          <OrgSelect
            onOrgSelect={onOrgSelect}
            orgUuid={orgUuid}
            error={!isTradingFeatureAvailable}
            isLoading={orgQuery.isLoading || tradingStatusQuery.isLoading}
            helpText={
              !isTradingFeatureAvailable &&
              (shouldShowContinueOnboarding ? (
                <div className="-ml-3">
                  <Link
                    to={`/accounts/${orgUuid}/`}
                    className="font-semi text-primary-600"
                    onClick={AppModalManager.close}
                  >
                    Complete
                  </Link>{" "}
                  the required account setup steps to start trading.
                </div>
              ) : (
                <div>This account is not enabled for trading.</div>
              ))
            }
          />
        </>
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: "Continue",
            onClick: onContinue,
            disabled: !orgUuid || !isTradingFeatureAvailable || orgQuery.isLoading,
          },
        ]}
      />
    </Modal>
  );
}
